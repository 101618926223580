import { useState, useEffect } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong, FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { Get } from "../../../config/api/get";
import { JOB } from "../../../config/constants/api";
import { useSelector } from "react-redux";
import { extractDate } from "../../../config/constants";

const Pricing = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const getJobDetails = () => {
    Get(`${JOB.getJob}${id}`, token)
    .then((response) => {
      setJobDetails(response?.data);
      setLoading(false);
      })
      .catch((err) => {
        console.log("Error Fetching Job Details", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getJobDetails();
  }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Careers Details</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {!loading ? (
              <Col lg={22}>
                <Card className="details-card">
                  <Row style={{ alignItems: "center" }}>
                    {jobDetails ? (
                      <Col lg={19}>
                        <h4 className="text-26">{jobDetails?.title}</h4>
                        <div className="jump-inner-detail">
                          <div>
                            <h6>Location</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <FaLocationDot />{" "}
                              {jobDetails?.location?.street +
                                " " +
                                jobDetails?.location?.city}
                            </p>
                          </div>

                          <div>
                            <h6>Experience</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <GrCertificate />{" "}
                              {jobDetails.experience + " years"}
                            </p>
                          </div>

                          <div>
                            <h6>Posted</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <FaClock /> {extractDate(jobDetails.createdAt)}
                            </p>
                          </div>
                          <div>
                            <h6>Location</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <FaLocationDot /> {jobDetails?.location?.city}
                            </p>
                          </div>
                        </div>
                        {jobDetails?.responsibilities?.length > 0 && (
                          <div className="">
                            <h3 className="text-20">
                              Key Responsibilities &amp; Accountabilities:
                            </h3>
                            {jobDetails?.responsibilities.map((item, index) => (
                              <div key={index}>
                                <p className="web-p">
                                  <span>{index + 1}</span> {item}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                        {jobDetails?.education?.length > 0 && (
                          <div className="">
                            <h3 className="text-20">Education & Experience</h3>
                            {jobDetails?.education.map((item, index) => (
                              <div key={index}>
                                <p className="web-p">
                                  <span>{index + 1}</span> {item}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                        {jobDetails?.certification?.length > 0 && (
                          <div className="">
                            <h3 className="text-20">Certification</h3>
                            {jobDetails?.certification.map((item, index) => (
                              <div key={index}>
                                <p className="web-p">
                                  <span>{index + 1}</span> {item}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                        {jobDetails?.attributes?.length > 0 && (
                          <div className="">
                            <h3 className="text-20">Personal Attributes</h3>
                            {jobDetails?.attributes.map((item, index) => (
                              <div key={index}>
                                <p className="web-p">
                                  <span>{index + 1}</span> {item}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                        {jobDetails?.skills?.length > 0 && (
                          <div className="" style={{ paddingBottom: "30px" }}>
                            <h3 className="text-20">Required Skills</h3>
                            {jobDetails?.skills?.map((item, index) => (
                              <div key={index}>
                                <p className="web-p">
                                  <span>{index + 1}</span> {item}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                        <Button type="link" className="web-btn">
                          Apply Now
                        </Button>
                        <span class="resume-at" style={{ paddingLeft: "10px" }}>
                          Send your resume at:
                          <span class="blue-text">careers@domainname.com</span>
                        </span>
                      </Col>
                    ) : (
                      <p>Some Issue while fetching job details for this job</p>
                    )}
                  </Row>
                </Card>
              </Col>
            ) : (
              <Col
                lg={22}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "150px 0px",
                }}
              >
                <Spin />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Pricing;
