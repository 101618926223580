import { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Rate, Typography, Divider, Spin } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  FaArrowLeftLong,
  FaLocationDot,
  FaParachuteBox,
} from "react-icons/fa6";
import { dropzoneData } from "../../components/Data/data";
import { MdSunny } from "react-icons/md";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { FaClock } from "react-icons/fa";
import Slider from "react-slick";
import { Get } from "../../config/api/get";
import { JUMP, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { extractTime } from "../../config/constants";


const Calls = () => {
  const location = useLocation();
  const [allImages, setAllImages] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jumpDetails, setJumpDetails] = useState(null);
  const companyId = location?.state?.companyId;
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const token = useSelector((state) => state.user.userToken);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [slider1?.current, slider2?.current, allImages]);
  useEffect(() => {
    Get(`${JUMP.getLatestJump}${companyId}`, token)
    .then((response) => {
      setJumpDetails(response?.data[0]);
      setAllImages([response?.data[0]?.gigDetails[0]?.image, ...response?.data[0]?.gigDetails[0]?.gallery]);
      setLoading(false)
    })
    .catch((err) => {
      console.log("Error Fetching Data", err);
      setLoading(false)
    });
  }, []);
  const navigate = useNavigate();

  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Calls</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {!loading ? (
            <Col lg={19}>
              {jumpDetails ? (<Card className="details-card">
                <Row align="middle" gutter={16}>
                  <Col lg={10}>
                    <div
                      className="one-product"
                      style={{ paddingRight: "30px" }}
                    >
                      <div className="search-img">
                        <div>
                        <Slider
                                asNavFor={nav2}
                                ref={slider1}
                                {...sliderSettings}
                              >
                                {Array.isArray(allImages) &&
                                  allImages.map((image, index) => {
                                    return (
                                      <div key={index}>
                                        <span>
                                          <img
                                            src={UPLOADS_URL + image}
                                            alt=""
                                            className="img-fluid"
                                            style={{
                                              width: "100%",
                                              maxHeight: "300px",
                                              objectFit: "cover",
                                              objectPosition: "center",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}
                              </Slider>
                          <Slider
                            asNavFor={nav1}
                            ref={slider2}
                            slidesToShow={jumpDetails?.gigDetails[0]?.gallery?.length}
                            swipeToSlide={true}
                            focusOnSelect={true}
                            {...sliderSettings2}
                          >
                             {Array.isArray(allImages) &&
                                  allImages.map((image, index) => {
                                    return (
                                      <div key={index} className="slider-nav">
                                        <span>
                                          <img
                                            src={UPLOADS_URL + image}
                                            alt=""
                                            className="img-fluid"
                                            style={{
                                              width: "100%",
                                              height: "90px",
                                              objectFit: "cover",
                                              objectPosition: "center",
                                              maxWidth: "150px",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}
                          </Slider>
                        </div>
                        <div className="rating-box">
                          <Rate
                            allowHalf
                            defaultValue={dropzoneData[0].rating}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  {jumpDetails && <Col lg={14}>
                    <div className="room-details">
                      <div>
                        <h4 className="text-26">{jumpDetails?.gigTitle}</h4>
                        <p className="">{jumpDetails?.jumpType + " Jump"}</p>
                      </div>
                      <div className="search-result-detail-btnbox">
                        <h6 className="per-day-price">
                          ${jumpDetails?.charges}
                        </h6>
                      </div>
                    </div>
                    <Divider dashed />
                    <div className="search-img-box-right additional-details">
                      <h5 className="heading-18">Additional Details</h5>
                      <p className="web-p">
                        {jumpDetails?.details}
                      </p>
                    </div>
                    <div className="jump-inner-detail">
                      <div>
                        <h6>Location</h6>
                        <p>
                          <FaLocationDot /> {jumpDetails?.gigDetails[0]?.location?.address + ', ' + jumpDetails?.gigDetails[0]?.location?.street + ' ' + jumpDetails?.gigDetails[0]?.location?.state }
                        </p>
                      </div>

                      <div>
                        <h6>Local Weather</h6>
                        <p>
                          <MdSunny /> {jumpDetails?.weather}
                        </p>
                      </div>

                      <div>
                        <h6>Jump Type</h6>
                        <p>
                          <FaParachuteBox /> {jumpDetails?.jumpType}
                        </p>
                      </div>
                    </div>

                    <div className="search-img-box-right">
                      <h5 className="heading-18 py-2">Flight Details</h5>
                      <Row
                        style={{ width: "100%", justifyContent: "center" }}
                        gutter={[16, 16]}
                      >
                        <Col xl={12} lg={24} md={12}>
                          <p>
                            <BiSolidPlaneAlt />
                            Plane Number:#{jumpDetails?.gigDetails[0]?.flightDetails?.planeNo}
                            <Divider type="vertical" />
                          </p>
                        </Col>
                        <Col xl={12} lg={24} md={12}>
                          <p>
                            <FaClock /> Flight Time: {extractTime(jumpDetails?.gigDetails[0]?.flightDetails?.flightTime)}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>}
                </Row>
              </Card>) : (<p style={{textAlign:'center' , fontWeight:'bold' , fontSize:'22px' , margin:'100px 0px'}}>Nothing to Show</p>)}
            </Col>):( <Col
                lg={19}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "150px 0px",
                }}
              >
                <Spin />
              </Col>)}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Calls;
