import React from "react";
import { Layout } from "antd";
import DropzonecompanysFiltercards from "../../views/dropzoneCompany/dropzonecompanysFiltercards";
import { useLocation } from "react-router-dom";

function DropzoneCompany() {
  const location = useLocation();
  const companyId = location.state.companyId;
  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
      <DropzonecompanysFiltercards companyId={companyId} />
    </Layout>
  );
}

export default DropzoneCompany;
