import { Col, Row, Image } from "antd";
import fb from "../../assets/fb.png";
import inst from "../../assets/inst.png";
import snap from "../../assets/snap.png";
import twitter from "../../assets/twitter1.png";
import youtube from "../../assets/youtube.png";
import linkedin from "../../assets/linkedin.png";
import tiktok from "../../assets/tiktok.png";
import footerlogo from "../../assets/footer-logo.png";
import { useNavigate } from "react-router";

const ClientFooter = () => {
  const navigate = useNavigate();

  return (
    <footer className="ant-footer">
      <div className="ant-container-fluid">
        <Row justify="center">
          <Col lg={20}>
            <div className="footer-box ant-footer-box">
              <Row>
                <Col lg={24} xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={footerlogo}
                      className=""
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                  <div className="for-footer-nav ant-for-footer-nav">
                    <ul className="brd-right">
                      <li>
                        <a onClick={() => navigate("/aboutUs")}>
                          About us / Our VISIONARIES
                        </a>
                      </li>
                      <li>
                        <a onClick={() => navigate("/news")}>News</a>
                      </li>
                      <li>
                        <a onClick={() => navigate("/faqs")}>FAQs</a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} xs={24}>
                  <div className="for-footer-nav ant-for-footer-nav">
                    <ul className="brd-right">
                      <li>
                        <a onClick={() => navigate("/career")}>Career</a>
                      </li>
                      <li>
                        <a onClick={() => navigate("/help")}>Help/Contact</a>
                      </li>
                      <li>
                        <a onClick={() => navigate("/termsConditions")}>
                          Terms & conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} xs={24}>
                  <div className="for-footer-nav ant-for-footer-nav">
                    <ul className="brd-right">
                      <li>
                        <a href="index.php">Investor Relations</a>
                      </li>
                      <li>
                        <a href="about.php">Partnership</a>
                      </li>
                      <li>
                        <a href="news.php">Sitemap</a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} xs={24}>
                  <div className="for-footer-nav ant-for-footer-nav">
                    <ul>
                      <li>
                        <a onClick={() => navigate("/privacyPolicy")}>
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a onClick={() => navigate("/privacyPolicy")}>
                          Sales & refund policy
                        </a>
                      </li>
                      <li>
                        <a href="about.php">Wind load Calculator</a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={24} xs={24}>
                  <div className="social-links ant-social-links">
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={fb}
                        className=""
                      />
                    </a>
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={twitter}
                        className=""
                      />
                    </a>
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={inst}
                        className=""
                      />
                    </a>
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={snap}
                        className=""
                      />
                    </a>
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={youtube}
                        className=""
                      />
                    </a>
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={linkedin}
                        className=""
                      />
                    </a>
                    <a href="#_">
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={tiktok}
                        className=""
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={24} xs={24}>
                  <p className="copyright-p ant-copyright-p">
                    {/* Copyrights 2023 - All Rights Reserved - Jump Community */}
                    —© 2023-2024 Skydivebnb.com LLC, all rights
                    reserved.priceline.com LLC is located at 800 Connecticut
                    Ave. Norwalk, CT 06854.
                    <br />
                    By skydivers for Skydivers
                    <div style={{ textAlign: "center" }}>
                      <Image
                      style={{width:"50px"}}
                        preview={false}
                        alt={"Failed to load image"}
                        src={footerlogo}
                        className=""
                      />
                    </div>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default ClientFooter;
