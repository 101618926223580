import { useEffect, useState } from "react";
import { Col, Row, Card, Typography, Steps, Button, Image, Rate } from "antd";
import { StarFilled, LikeFilled } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { cardriverData } from "../../../components/Data/data";
import { RIDE, UPLOADS_URL } from "../../../config/constants/api";
import { Get } from "../../../config/api/get";
import socket from "../../../config/socket/index";
import { useSelector } from "react-redux";
const { Title, Text } = Typography;

const Pricing = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const token = useSelector((state) => state.user.userToken);
  const [ongoingRide, setOngoingRide] = useState(null);
  const [message, setMessage] = useState("");
  const getOngoingRide = async () => {
    Get(RIDE.getUserOngoingRide, token)
      .then((response) => {
        if (response?.status) {
          setOngoingRide(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    socket.on("statusChanged", (data) => {
      const { status } = data;
      setOngoingRide((prevOngoingRide) => {
        if (prevOngoingRide) {
          return {
            ...prevOngoingRide,
            ride: {
              ...prevOngoingRide.ride,
              status: status,
            },
          };
        }
        return prevOngoingRide;
      });
    });
    return () => {
      socket.off("statusChanged");
    };
  }, []);
  useEffect(() => {
    getOngoingRide();
  }, []);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  useEffect(() => {
    if (ongoingRide?.ride?.status === "ACCEPTED") {
      setMessage(`Arriving in ${ongoingRide?.ride?.approximatedReachTime}`);
    } else if (ongoingRide?.ride?.status === "ARRIVED") {
      setMessage("Your driver has arrived");
    } else if (ongoingRide?.ride?.status === "STARTED") {
      setMessage("Your ride has started");
    } else if (ongoingRide?.ride?.status === "ENDED") {
      setMessage("Your ride has ended");
    }
  }, [ongoingRide]);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        {ongoingRide && (
          <Col xs={23} md={21}>
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col lg={24}>
                <div class="arrow-box">
                  <FaArrowLeftLong
                    className="arrow"
                    onClick={() => navigate("/")}
                  />
                  <h3 className="main-heading">Book a Ride</h3>
                </div>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Col lg={22}>
                <div className="map-bg">
                  <Row
                    justify="center"
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <Col lg={9}>
                      <div className="details-card" style={{ padding: "0" }}>
                        <div
                          className="arriving-heading"
                          style={{
                            backgroundColor:
                              ongoingRide?.ride?.status === "ACCEPTED"
                                ? "#1497E4"
                                : ongoingRide?.ride?.status === "ARRIVED"
                                ? "#09BE51"
                                : ongoingRide?.ride?.status === "STARTED"
                                ? "#09BE51"
                                : ongoingRide?.ride?.status === "ENDED"
                                ? "red"
                                : "red",
                          }}
                        >
                          <Title level={4} style={{ color: "#fff" }}>
                            {message}
                          </Title>
                        </div>
                        <div className="arriving-detail">
                          <div className="for-media-object">
                            <div className="flex-shrink-0">
                              <span>
                                <Image
                                  src={
                                    UPLOADS_URL +
                                    ongoingRide?.ride?.driver?.image
                                  }
                                  alt=""
                                  className="img-fluid"
                                  preview={false}
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50px",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                />
                              </span>
                            </div>
                            <div
                              className="flex-grow-1 ms-3"
                              style={{ flexGrow: "1" }}
                            >
                              {ongoingRide?.averageRating !== 0 && (
                                <div className="cap-stars">
                                  <Rate
                                    character={<StarFilled />}
                                    allowHalf
                                    defaultValue={ongoingRide?.averageRating}
                                    disabled
                                  />
                                </div>
                              )}
                              <Title level={5} className="capt-name">
                                {ongoingRide?.ride?.driver?.firstName +
                                  " " +
                                  ongoingRide?.ride?.driver?.lastName}
                              </Title>
                              <Text className="car-name">
                                {ongoingRide?.vehicle?.brandName}
                              </Text>
                            </div>
                            <div
                              className="flex-grow-1 ms-2"
                              style={{ flexGrow: "1" }}
                            >
                              <div className="num-plate">
                                <Title level={5} style={{ margin: "0" }}>
                                  {ongoingRide?.vehicle?.licenseNo}
                                </Title>
                              </div>
                            </div>
                          </div>
                        </div>
                        {ongoingRide?.ride?.status === "ENDED" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                              Please pay ${Math.round(ongoingRide?.ride?.fare)}{" "}
                              Rupees
                            </p>

                            <Button
                              style={{
                                cursor: "pointer",
                                marginBottom: "5px",
                              }}
                              className="web-btn"
                              onClick={() => {
                                navigate("/payment", {
                                  state: {
                                    total: Math.round(ongoingRide?.ride?.fare),
                                    type: "RIDE",
                                    ride: ongoingRide?.ride,
                                  },
                                });
                              }}
                            >
                              Pay Now
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Pricing;
