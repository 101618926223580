import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm/index";
import { loadStripe } from "@stripe/stripe-js";
import { Get } from "../../config/api/get";
import { PAYMENT } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import payment from "./payment.css";

function Payment(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const total = location?.state?.total || props.ptotal;
  const type = location?.state?.type || props.ptype;
  const ride = location?.state?.ride;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const token = useSelector((state) => state.user.userToken);
  useEffect(() => {
    if (!(type && total)) {
      navigate(-1);
    }
    Get(PAYMENT.paymentConfig, token)
      .then((response) => {
        const publishableKey = response.data?.publishableKey;
        setStripePromise(loadStripe(publishableKey));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  useEffect(() => {
    let body = {
      amount: total,
      // amount: 100,
      currency: "USD",
    };
    Post(PAYMENT.paymentIntent, body, token)
      .then((response) => {
        const clientSecret = response.data?.clientSecret;
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        console.log(err, "Intent error");
      });
  }, []);
  return (
    <>
      <Row gutter={[16, 16]} justify={"center"}>
        <Col lg={13}>
          <div className="payment-body">
            <h1>React Stripe and the Payment Element</h1>
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm type={type} amount={total} ride={ride} />
              </Elements>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Payment;
