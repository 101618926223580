import React from "react";
import { Layout } from "antd";
import FindNextStayBox from "../../views/findNextStay/findNextStayBox";

function ServiceProvidersPrivateEvent() {
  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
    <FindNextStayBox/>
    </Layout>
  );
}

export default ServiceProvidersPrivateEvent;
