import { useEffect, useState } from "react";
import { Col, Row, Rate, Button, Pagination } from "antd";
import { useLocation, useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdBalcony, MdOutlineKingBed } from "react-icons/md";
import { FaShower } from "react-icons/fa";
import { bookingDate } from "../../../components/Data/data";
import { STAY, UPLOADS_URL } from "../../../config/constants/api";
import { Get } from "../../../config/api/get";
import { useSelector } from "react-redux";

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Earth's radius in miles
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  const roundedDistance = Math.round(distance * 2) / 2;
  return roundedDistance;
};

const AsNavFor = () => {
  const token = useSelector((state) => state.user.userToken);
  const location = useLocation();
  const data = location?.state?.rooms;
  const { log, lat, beds, guests, to, from } = location?.state;
  const { pageNumber, limit, totalDocs, totalPages } = location?.state;
  const [rooms, setRooms] = useState(null);
  useEffect(() => {
    setPaginationConfig({
      pageNumber,
      limit,
      totalDocs,
      totalPages,
    });
  }, []);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = rooms
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";

  useEffect(() => {
    setRooms(data);
  }, []);
  const getAllRooms = (pageNumber) => {
    if (paginationConfig.totalPages > 1) {
      setPaginationConfig({
        ...paginationConfig,
        pageNumber: pageNumber,
      });
      let params = {
        page: pageNumber ? pageNumber.toString() : "1",
        limit: paginationConfig.limit.toString(),
        log,
        lat,
        startDate: from,
        endDate: to,
        beds: beds,
        guests,
      };
      Get(STAY.getAllRooms, token, params)
        .then((response) => {
          if (response?.status) {
            setRooms(response?.data?.docs);
            setPaginationConfig({
              pageNumber: response?.data?.page,
              limit: response?.data?.limit,
              totalDocs: response?.data?.totalDocs,
              totalPages: response?.data?.totalPages,
            });
          }
        })
        .catch((err) => {
          console.log("Error fetching rooms", err);
        });
    } else {
      return;
    }
  };
  const navigate = useNavigate();

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Book a Stay - Search Result</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {rooms && (
              <Col span={24} lg={19}>
                {Array.isArray(rooms) &&
                  rooms.map((room, index) => (
                    <div key={index} className="details-card">
                      <Row align="middle">
                        <Col span={14}>
                          <div className="search-img-box">
                            <div
                              className="search-img"
                              style={{ maxWidth: "380px" }}
                            >
                              <img
                                src={UPLOADS_URL + room?.image}
                                alt=""
                                className="img-fluid"
                                style={{ width: "100%" }}
                              />
                              {room?.averageRating && <div className="rating-box">
                                <Rate
                                  allowHalf
                                  defaultValue={room?.averageRating}
                                  disabled
                                />
                              </div>}
                            </div>
                            <div className="search-img-box-right">
                              <h4>
                                {haversineDistance(
                                  lat,
                                  log,
                                  room.location.coordinates[1],
                                  room.location.coordinates[0]
                                ) < 1
                                  ? "Under 1 Mile"
                                  : haversineDistance(
                                    lat,
                                    log,
                                    room.location.coordinates[1],
                                    room.location.coordinates[0]
                                  )+ ' Miles away'}
                              </h4>
                              <h4>{room?.title}</h4>
                              <p>
                                <MdBalcony />
                                {room?.description}
                              </p>
                              <p>
                                <FaShower />
                                {room?.bathrooms + " Bathrooms"}
                              </p>
                              <p>
                                <MdOutlineKingBed />
                                {room?.beds + " Beds"}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col span={10}>
                          <div className="search-result-detail-btnbox">
                            <h6 className="per-day-price">
                              ${room?.pricing}
                              <span>/Per day</span>
                            </h6>
                            <p>{"Space for " + room?.guests + " guests"}</p>
                            <Button
                              type=""
                              htmlType="submit"
                              className="btn web-btn"
                              onClick={() =>
                                navigate("/staySearchResult/" + room?._id, {
                                  state: {
                                    to: to,
                                    from: from,
                                    availableDates: room?.availableDates,
                                  },
                                })
                              }
                              style={{ marginTop: "20px" }}
                            >
                              View Details
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}

                <Row align="middle">
                  <Col span={24}>
                    <div
                      className="d-md-flex align-items-center justify-content-between"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="m-grey-text mb-0">{message}</p>
                      <div aria-label="...">
                        <Pagination
                          current={paginationConfig?.pageNumber}
                          total={paginationConfig?.totalDocs}
                          onChange={(num) => {
                            getAllRooms(num);
                          }}
                          disabled={!paginationConfig.totalPages>1}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
            {!rooms && (
              <Col
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  margin: "120px 0px",
                }}
              >
                No Rooms to show
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AsNavFor;
