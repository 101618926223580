import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import { useLocation, useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Get } from "../../config/api/get";
import { INSTRUCTOR, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";

const InstructorsRiggers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.user.userToken);
  const companyId = location?.state?.companyId;
  const [instructors, setInstructors] = useState(null);
  const [loading, setLoading] = useState(true);

  const getIntructorsData = () => {
    Get(INSTRUCTOR.getAllInstructors, token, { companyId })
    .then((response) => {
      setInstructors(response?.data?.docs);
      setLoading(false)
    })
    .catch((err) => {
      console.log("Error Fetching Instructors Data", err);
      setLoading(false)
    });
  };
  useEffect(() => {
    getIntructorsData();
  }, []);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Instructors & Riggers</h3>
              </div>
            </Col>
          </Row>  
          <Row justify="center" style={{ alignItems: "baseline" }}>
            {!loading ? (
            <Col lg={22}>
              {Array.isArray(instructors) && instructors.length > 0 ? (
                instructors.map((instructorData, index) => (
                  <Card className="details-card" key={index}>
                    <Row style={{ alignItems: "center" }}>
                      <Col lg={6}>
                        <h3 className="text-20">Name</h3>
                        <div className="jump-inner-detail">
                          <div className="for-media-object">
                            <span>
                              {" "}
                              <img
                                src={UPLOADS_URL + instructorData?.image}
                                alt=""
                                className="img-fluid"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50px",
                                }}
                              />
                            </span>
                            <div>
                              <h6 className="instructor-name">
                                {instructorData?.fullName}
                                <h4 className="blue-text">
                                  {instructorData?.designation}
                                </h4>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={14}>
                        <h3 className="text-20">Description</h3>
                        <div className="jump-inner-detail">
                          <div className="for-media-object">
                            <p className="web-p">
                              {instructorData?.description}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Button
                          type="link"
                          className="web-btn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onClick={() =>
                            navigate(
                              "/dropzoneCompany/instructorsRiggers/" +
                                instructorData?._id
                            )
                          }
                        >
                          View Details
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bold",
                    margin: "150px 0px",
                  }}
                >
                  No Intructors Found For this Dropzone Company
                </p>
              )}
            </Col>
            ):( <Col
              lg={22}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "150px 0px",
              }}
            >
              <Spin />
            </Col>)}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default InstructorsRiggers;
