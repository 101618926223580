import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Select,
  Input,
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Form,
  Space,
} from "antd";
import { useNavigate } from "react-router";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import swal from "sweetalert";
import { Get } from "../../../config/api/get";
import { RIDE } from "../../../config/constants/api";
import { useSelector } from "react-redux";

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const { RangePicker } = DatePicker;

const FindCar = () => {
  const navigate = useNavigate();
  const [loading , setLoading] = useState(true)
  const token = useSelector((state) => state.user.userToken);
  const [pickupLongitude, setPickupLongitude] = useState("");
  const [pickupLatitude, setPickupLatitude] = useState("");
  const [destLongitude, setDestLongitude] = useState("");
  const [destLatitude, setDestLatitude] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [destAddress, setDestAddress] = useState("");
  const getOngoingRide = async () =>{
    setLoading(true)
    Get(RIDE.getUserOngoingRide, token).then((response)=>{
      if(response?.status){
        navigate('/driverCarArrival', {state : {data : response?.data}})
        setLoading(false)
      }
    }).catch((err)=>{
      setLoading(false)
      console.log(err)
    })
  }
  useEffect(()=>{
    getOngoingRide()
  },[])
  const handleSubmit = () => {
    if (
      !pickupLatitude ||
      !pickupLongitude ||
      !destLatitude ||
      !destLongitude
    ) {
      swal(
        "error",
        "Both pickup and destination locations are required",
        "error"
      );
      return;
    }
    const pickupLocation = {
      coordinates : [pickupLongitude,pickupLatitude],
      address: pickupAddress,
    };
    const destinationLocation = {
      coordinates : [destLongitude,destLatitude],
      address: destAddress,
    };
    navigate("/mapLocationAdded", {
      state: { pickupLocation, destinationLocation },
    });
  };
  const handlePickupLocChange = (newAddress) => {
    setPickupAddress(newAddress);
  };
  const handleDestLocChange = (newAddress) => {
    setDestAddress(newAddress);
  };
  const handlePickupSelect = (newAddress) => {
    setPickupAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setPickupLongitude(latLng?.lng);
        setPickupLatitude(latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };
  const handleDestSelect = (newAddress) => {
    setDestAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setDestLongitude(latLng?.lng);
        setDestLatitude(latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };
  return (
    <section className="auth-banner">
      <Row justify="center" style={{ width: "100%", justifyContent: "center" }}>
        {!loading && <Col lg={16}>
          <div className="auth-box">
            <h2 className="auth-heading">Book a Ride</h2>
            <p className="auth-p">Focused on safety, wherever you go</p>

            <Row justify={"center"} gutter={[16, 16]}>
              <Col md={8}>
                <PlacesAutocomplete
                  value={pickupAddress}
                  onChange={handlePickupLocChange}
                  onSelect={handlePickupSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    return (
                      <div className="location-drop">
                        <input
                          {...getInputProps({
                            placeholder: "Search Pickup location here...",
                            className: "location-search-input",
                            style: {
                              position: "relative",
                              width: "100%",
                              marginBottom: "10px",
                              height: "52px",
                              borderRadius: "6px",
                              border: "1px solid #E3E3E3",
                              backgroundColor: "#F8F8F8",
                              padding: "10px 50px 10px 10px",
                            },
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active googleSuggestion"
                              : "suggestion-item googleSuggestion";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </Col>
              <Col md={8}>
                <PlacesAutocomplete
                  value={destAddress}
                  onChange={handleDestLocChange}
                  onSelect={handleDestSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    return (
                      <div className="location-drop">
                        <input
                          {...getInputProps({
                            placeholder: "Search Destination location here...",
                            className: "location-search-input",
                            style: {
                              position: "relative",
                              width: "100%",
                              marginBottom: "10px",
                              height: "52px",
                              borderRadius: "6px",
                              border: "1px solid #E3E3E3",
                              backgroundColor: "#F8F8F8",
                              padding: "10px 50px 10px 10px",
                            },
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active googleSuggestion"
                              : "suggestion-item googleSuggestion";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </Col>
              <Col md={6}>
                <Button
                  type=""
                  // htmlType="submit"
                  onClick={handleSubmit}
                  className="btn web-btn"
                  style={{ width: "100%" }}
                >
                  Find Me A CAR
                </Button>
              </Col>
            </Row>
          </div>
        </Col>}
      </Row>
    </section>
  );
};

export default FindCar;
