import React from "react";
// import AuthLayout from "../../components/";
import { Layout, Input } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import Topbanner from "../../views/homepage/topbanner";
import AllservicesHome from "../../views/homepage/allservicesHome";
import AboutUsHome from "../../views/homepage/aboutUsHome";
import DriverStaysHome from "../../views/homepage/driverStaysHome";
import TestimonialsHome from "../../views/homepage/testimonialsHome";
import ClientTestimonialsHome from "../../views/homepage/clientTestimonialsHome";
import BookingSliderHome from "../../views/homepage/bookingSliderHome";
import Footer from "../../components/ClientLayout/ClientFooter";

// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <section className="home-banner">
        <Topbanner />
        <AllservicesHome />
        <AboutUsHome />
        <BookingSliderHome />
        <DriverStaysHome />
      </section>
      <section className="footer-bg">
        <TestimonialsHome />
        <ClientTestimonialsHome />
        <Footer />
      </section>
    </Layout>
  );
}

export default Homepage;
