import { useEffect, useState } from "react";
import { Col, Row, Card, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Get } from "../../../config/api/get";
import { INSTRUCTOR, UPLOADS_URL } from "../../../config/constants/api";

const InstructorsRiggersDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = useSelector((state) => state.user.userToken);
  const [instructorDetails, setInstructorDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const getInstructorDetails = () => {
    Get(`${INSTRUCTOR.getInstructor}${id}`, token)
    .then((response) => {
      setInstructorDetails(response?.data);
      setLoading(false);
      })
      .catch((err) => {
        console.log("Error Fetching Instructor Details", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getInstructorDetails();
  }, []);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        {!loading ? (
          <Col xs={23} md={21}>
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col lg={24}>
                <div className="arrow-box">
                  <FaArrowLeftLong
                    className="arrow"
                    onClick={() => navigate(-1)}
                  />
                  <h3 className="main-heading">
                    Instructors & Riggers - Details
                  </h3>
                </div>
              </Col>
            </Row>
            {instructorDetails ? (
              <Row justify="center" style={{ alignItems: "baseline" }}>
                <Col lg={22}>
                  <Card className="details-card">
                    <Row style={{ alignItems: "" }}>
                      <Col lg={6}>
                        <h3 className="text-20">Name</h3>
                        <div className="jump-inner-detail">
                          <div className="for-media-object">
                            <span>
                              <img
                                src={UPLOADS_URL + instructorDetails?.image}
                                alt=""
                                className="img-fluid"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50px",
                                }}
                              />
                            </span>
                            <div>
                              <h6 className="instructor-name">
                                {instructorDetails?.fullName}
                                <span className="blue-text">
                                  {" (" + instructorDetails?.designation + ")"}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={18}>
                        <h3 className="text-20">Description</h3>
                        <div className="jump-inner-detail">
                          <div className="for-media-object">
                            <p className="web-p">
                              {instructorDetails?.description}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ alignItems: "" }}>
                      <Col lg={18}>
                        <h3 className="text-20">Tip Details:</h3>
                        <div className="jump-inner-detail">
                          <div>
                            <h6 className="instructor-name">
                              Cashapp #{" "}
                              <span className="">
                                {instructorDetails?.cashappNo}
                              </span>
                            </h6>
                            <h6 className="instructor-name">
                              Venmo #{" "}
                              <span className="">
                                {instructorDetails?.venmoNo}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "22px",
                  margin: "100px 0px",
                }}
              >
                Problem Fetching Instructor Details
              </p>
            )}
          </Col>
        ) : (
          <Col
            xs={23}
            md={21}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "150px 0px",
            }}
          >
            <Spin />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InstructorsRiggersDetails;
