import { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import { giftCardsdata } from "../../../components/Data/data";
import { Get } from "../../../config/api/get";
import { COUPON } from "../../../config/constants/api";
import { extractDate } from "../../../config/constants";

const DropzoneFiltercards = () => {
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllCoupons();
  }, []);

  const getAllCoupons = () => {
    setLoading(true);
    Get(COUPON.getCoupons, token)
      .then((response) => {
        setCoupons(response?.data?.docs);
      })
      .catch((err) => {
        console.log("Error Fecthing Coupons Data", err);
      });
    setLoading(false);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              {!loading ? (
                <Col xs={24} md={24} lg={24} xl={24}>
                  <div className="my-account-profile">
                    <section className="side-menu-parent">
                      <DashbordSidebar />
                      <div className="about-us-section">
                        <div className="profile-information-wrapper">
                          <h3 className="main-heading">Gift Cards</h3>
                        </div>
                        <div className="bg-parent">
                          <Row
                            gutter={[16, 16]}
                            align={"middle"}
                            justify={"space-between"}
                          >
                            <Col md={24} lg={24} xl={24}>
                              {Array.isArray(coupons) && coupons.map((coupon, index) => (
                                <div className="for-media-object" key={index}>
                                  <div
                                    className="flex-shrink-0"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate("/giftCards/" + coupon._id )
                                    }
                                  >
                                    {giftCardsdata[0]?.cardPic}
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <h6 className="web-p">
                                      {'Available from ' + extractDate(coupon?.startDate) + ' to ' + extractDate(coupon?.expiryDate)}
                                    </h6>
                                    <h6 className="text-24-bold">
                                       {"Gift Card Of $" +coupon?.amount}
                                    </h6>
                                    <h6 className="text-16">
                                      Coupon Number: 
                                     <span style={{color:'#1497E4'}}
                                     > {" #"+coupon?.codeName}</span>
                                    </h6>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </section>
                  </div>
                </Col>
              ) : (
                <Col
                  xs={23}
                  md={21}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "150px 0px",
                  }}
                >
                  <Spin />
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
