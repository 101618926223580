import { useState, useEffect } from "react";
import { Col, Row, Form, Input, Button, DatePicker } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import { giftCardsdata } from "../../../components/Data/data";
import { Get } from "../../../config/api/get";
import { COUPON, GIFT } from "../../../config/constants/api";
import { Post } from "../../../config/api/post";
import moment from "moment";
import swal from "sweetalert";
import { addData } from "../../../redux/slice/afterPaymentSlice";

const PurchaseGiftCards = () => {
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [couponInfo, setCouponInfo] = useState(null);
  const { id } = useParams();
  const [form] = Form.useForm();
  const disabledDate = (current) => {
    if (couponInfo) {
      const startDate = new Date(couponInfo?.startDate);
      const expiryDate = new Date(couponInfo?.expiryDate);
      const today = new Date();
      return current < startDate || current > expiryDate || current < today;
    }
  };
  const { TextArea } = Input;
  const getCoupon = () => {
    Get(`${COUPON.getCoupon}${id}`, token)
      .then((response) => {
        setCouponInfo(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCoupon();
  }, []);
  const onFinish = (values) => {

    let giftData = {
      amount: couponInfo?.amount,
      to: values?.email,
      from: values?.senderName,
      message: values?.message,
      deliveryDate: moment(values?.deliveryDate?.$d).format("YYYY-MM-DD"),
      startDate: couponInfo?.startDate,
      expiryDate: couponInfo?.expiryDate,
      couponCode: couponInfo?.codeName,
    };
    dispatch(addData({ type: "GIFT", data: giftData }));
    form.resetFields()
    navigate("/payment", {
      state: { total: couponInfo?.amount, type: "GIFT" },
    });

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    return;
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Purchase Gift Cards</h3>
                      </div>
                      <div className="bg-parent">
                        <h4
                          className="text-22-urb"
                          style={{ textAlign: "end", paddingRight:"15px" }}
                        >
                          Total Payable: ${couponInfo?.amount}{" "}
                        </h4>
                        <Row
                          gutter={[16, 16]}
                          align={""}
                          justify={"space-between"}
                        >
                          <Col md={10} lg={12} xl={12}>
                            <div className="">{giftCardsdata[0]?.cardPic}</div>
                          </Col>
                          <Col md={14} lg={12} xl={12}>
                            <Form
                              className="row g-3"
                              name="basic"
                              layout="vertical"
                              initialValues={{
                                remember: true,
                              }}
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                              autoComplete="off"
                              form={form}
                            >
                              <Row style={{ width: "100%" }} gutter={[16, 16]}>
                                <Col lg={24} md={24} xs={24}>
                                  <Form.Item
                                    label="Recipient email"
                                    name="email"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter email!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="large"
                                      placeholder="Enter recipient email address"
                                      className="web-input"
                                      style={{width:"100%"}}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={24} md={24} xs={24}>
                                  <Form.Item
                                    label="Enter sender's name"
                                    name="senderName"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter Enter sender's name !",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="large"
                                      placeholder="Enter sender's name "
                                      className="web-input"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={24} md={24} xs={24}>
                                  <Form.Item
                                    label="Message"
                                    name="message"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Please enter your Message",
                                      },
                                    ]}
                                  >
                                    <TextArea
                                      rows={4}
                                      placeholder="Thank you for your all hard work!"
                                      // maxLength={6}
                                      className="web-textarea"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={24} md={12} xs={24}>
                                  <Form.Item
                                    label="Delivery Date"
                                    name="deliveryDate"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter your Delivery Date!",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      className="web-input"
                                      onChange={onChange}
                                      disabledDate={disabledDate}
                                    />
                                  </Form.Item>
                                </Col>
                                <div
                                  className=""
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    type=""
                                    htmlType="submit"
                                    className="btn web-btn px-5"
                                  >
                                    Proceed to pay
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseGiftCards;
