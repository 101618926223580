import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Popover,
  Skeleton,
  Table,
  Select,
  Pagination,
  DatePicker,
  Card,
  Tabs,
} from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { JUMP } from "../../config/constants/api";
import moment from "moment";
import { extractDate } from "../../config/constants";

function Myloads() {
  const token = useSelector((state) => state.user.userToken);
  const location = useLocation();
  const companyId = location?.state?.companyId;
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loads, setLoads] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageNo, setPageNo] = useState("1");
  const [users, setUsers] = useState([
    {
      id: 1,
      fullname: "John Doe",
      email: "huamj@email.com",
      phone: "+1234567890",
      amount: "$450.00",
      isPay: true,
    },
    {
      id: 2,
      fullname: "Jane Smith",
      email: "huamj@email.com",
      phone: "+1234567890",
      amount: "$350.00",
      isPay: false,
    },
    {
      id: 3,
      fullname: "Bob Johnson",
      email: "huamj@email.com",
      phone: "+1234567890",
      amount: "$420.00",
      isPay: false,
    },
    // Add more user objects as needed
  ]);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    status: null,
    keyword: "",
    from: null,
    to: null,
  });
  const data = {
    status: filter.status,
    keyword: filter.keyword,
    from: filter.from,
    to: filter.to,
  };
  const handleApply = () => {
    let status;
    switch (pageNo) {
      case "1":
        status = null;  
        break;
      case "2":
        status = "COMPLETED";
        break;
      case "3":
        status = "UPCOMING";
        break;
      default:
        return;
    }
    let from;
    let to;
    if (data.from) {
      from = moment(filter?.from?.$d).format("YYYY-MM-DD");
    }
    if (data.to) {
      to = moment(filter?.to?.$d).format("YYYY-MM-DD");
    }
    if (from || to) {
      getMyLoads( paginationConfig.pageNumber , paginationConfig.limit, status, from, to);
    } else {
      return;
    }
  };
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;
  //   const getUsers = async (pageNumber, pageSize, search, reset = false) => {
  //     setLoading(true);
  //     try {
  //       const response = await Get(USERS.get, token, {
  //         page: pageNumber
  //           ? pageNumber.toString()
  //           : paginationConfig.pageNumber.toString(),
  //         limit: pageSize
  //           ? pageSize.toString()
  //           : paginationConfig.limit.toString(),
  //         status: reset ? "" : filter.status || null,
  //         keyword: search ? search : null,
  //         from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
  //         to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
  //       });
  //       setLoading(false);
  //       console.log("response", response);
  //       if (response?.docs) {
  //         setUsers(response?.docs);
  //         setPaginationConfig({
  //           pageNumber: response?.page,
  //           limit: response?.limit,
  //           totalDocs: response?.totalDocs,
  //           totalPages: response?.totalPages,
  //         });
  //       } else {
  //         message.error("Something went wrong!");
  //         console.log("error====>", response);
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //       setLoading(false);
  //     }
  //   };

  const getMyLoads = (pageNumber, pageSize, status, from, to) => {
    Get(JUMP.getMyJumps,   token, {
      companyId,
      status,
      from,
      to,
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
        limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      })
      .then((response) => {
        if (response?.data?.docs) {
          setLoads(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
          setLoading(false); 
        }
      })
      .catch((err) => {
        console.log("Error Fetching Loads", err);
        setLoading(false); 
      });
  };
  useEffect(() => {
    getMyLoads();
  }, []);
  const handleClear = () => {
    resetFilter();
    if (pageNo === "1") {
      getMyLoads();
    }
    if (pageNo === "2") {
      getMyLoads(paginationConfig.pageNumber, paginationConfig.limit,"COMPLETED");
    }
    if (pageNo === "3") {
      getMyLoads(paginationConfig.pageNumber, paginationConfig.limit, "UPCOMING");
    }
  };
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    let status;
    switch (pageNo) {
      case "1":
        status = null;
        break;
      case "2":
        status = "COMPLETED";
        break;
      case "3":
        status = "UPCOMING";
        break;
      default:
        return;
    }
    getMyLoads(pageNumber , paginationConfig.limit, status);
    // getUsers(pageNumber);
  };

  // const handleSearch = (value) => {
  //   setFilter({
  //     ...filter,
  //     keyword: value,
  //   });
  // };

  const handleStatusChange = (value) => {
    setFilter({
      ...filter,
      status: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      from: null,
      to: null,
    });
    // getUsers(paginationConfig.pageNumber, paginationConfig.limit, "", true);
  };

  // const handleOpenChange = (newOpen) => {
  //   setOpen(newOpen);
  // };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      from: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      to: date,
    });
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    // getUsers(1, pageSize);
  };

  const handleStatus = async () => {
    try {
      const index = users.findIndex((user) => user.id == selectedUser.id);
      const newUsers = [...users];

      newUsers[index].isActive = !selectedUser.isActive;
      setModalOpen(false);
      setUsers(newUsers);
    } catch (error) {
      console.log(error.message);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 10 && "0") + (index + 1),
    },
    {
      title: "Load",
      dataIndex: "gig_info",
      key: "gig_info",
      render: (item) => <span>{item[0]?.flightDetails?.loadNo}</span>,
    },
    {
      title: "Jump Type",
      dataIndex: "jumpType",
      key: "jumpType",
    },
    {
      title: "Date",
      dataIndex: "selectedDate",
      key: "selectedDate",
      render: (item) => <span>{extractDate(item)}</span>,
    },
    {
      title: "Description",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Amount",
      dataIndex: "charges",
      key: "charges",
      render: (value, item) => (
        <span
          className={item?.status === "UPCOMING" ? "greenSelect" : "redSelect"}
        >
          {item?.charges}
        </span>
      ),
    },
  ];

  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p className="mainLabel text-22" style={{ padding: "10px" }}>
          Filter
        </p>
      </div>
      <hr style={{ margin: 0 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel">Sort By Date:</p>
        <p className="mainLabel">From</p>
        <DatePicker
          className="mainInput filterInput web-input"
          value={filter.from}
          onChange={(e) => handleFrom(e)}
          style={{ width: "100%" }}
        />
        <p className="mainLabel">To</p>
        <DatePicker
          className="mainInput filterInput web-input"
          value={filter.to}
          onChange={(e) => handleTo(e)}
          style={{ width: "100%" }}
        />

        <Button
          type=""
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="web-btn"
          //   onClick={() => getUsers()}
          onClick={handleApply}
        >
          Apply
        </Button>
        <Button
          type=""
          block
          size={"large"}
          className="web-btn"
          onClick={handleClear}
        >
          Clear All
        </Button>
      </div>
    </div>
  );

  const onChange = (key) => {
    if (key === "1") {
      getMyLoads();
      setPageNo("1");
    }
    if (key === "2") {
      getMyLoads(paginationConfig.pageNumber,paginationConfig.limit,"COMPLETED");
      setPageNo("2");
    }
    if (key === "3") {
      getMyLoads(paginationConfig.pageNumber,paginationConfig.limit,"UPCOMING");
      setPageNo("3");
    }
  };
  const items = [
    {
      key: "1",
      label: "All",
      children: (
        <Row align="middle" gutter={16}>
          <Col lg={24}>
            <div className="boxDetails">
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <h5 style={{ display: "inline", fontSize: 16 }}>Show : </h5>
                  <Select
                    size={"large"}
                    className="chartSelectBox"
                    defaultValue={paginationConfig?.limit}
                    onChange={(e) => handleLimitChange(e)}
                    style={{
                      width: 70,
                      textAlign: "left",
                    }}
                    options={[
                      { value: 10, label: "10" },
                      { value: 20, label: "20" },
                      { value: 30, label: "30" },
                      { value: 40, label: "40" },
                      { value: 50, label: "50" },
                    ]}
                  />
                  &emsp;
                  <h5 style={{ display: "inline", fontSize: 16 }}>Entries</h5>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  &emsp;
                  <Popover
                    content={filterContent}
                    trigger="click"
                    open={openOne}
                    onOpenChange={(newOpen)=>{setOpenOne(newOpen)}}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <Button
                      style={{
                        padding: "10px 15px",
                        height: "auto",
                      }}
                      className="fltr-btn"
                    >
                      <FaFilter style={{ fontSize: "16px", color: "#000" }} />
                    </Button>
                  </Popover>
                </Col>
              </Row>

              <Row style={{ padding: 20, overflow: "auto" }}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Skeleton active />
                    <br />
                  </div>
                ) : (
                  <Table
                    className="styledTable"
                    dataSource={loads}
                    columns={columns}
                    pagination={false}
                  />
                )}
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <p>{message}</p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    className="styledPagination"
                    onChange={(e) => handlePageChange(e)}
                    current={parseInt(paginationConfig?.pageNumber)}
                    pageSize={paginationConfig?.limit}
                    total={paginationConfig?.totalDocs}
                    itemRender={itemRender}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Jumps",
      children: (
        <Row align="middle" gutter={16}>
          <Col lg={24}>
            <div className="boxDetails">
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <h5 style={{ display: "inline", fontSize: 16 }}>Show : </h5>
                  <Select
                    size={"large"}
                    className="chartSelectBox"
                    defaultValue={paginationConfig.limit}
                    onChange={(e) => handleLimitChange(e)}
                    style={{
                      width: 70,
                      textAlign: "left",
                    }}
                    options={[
                      { value: 10, label: "10" },
                      { value: 20, label: "20" },
                      { value: 30, label: "30" },
                      { value: 40, label: "40" },
                      { value: 50, label: "50" },
                    ]}
                  />
                  &emsp;
                  <h5 style={{ display: "inline", fontSize: 16 }}>Entries</h5>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  &emsp;
                  <Popover
                    content={filterContent}
                    trigger="click"
                    open={openTwo}
                    onOpenChange={(newOpen)=>{setOpenTwo(newOpen)}}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <Button
                      style={{
                        padding: "10px 15px",
                        height: "auto",
                      }}
                      className="fltr-btn"
                    >
                      <FaFilter style={{ fontSize: "16px", color: "#000" }} />
                    </Button>
                  </Popover>
                </Col>
              </Row>

              <Row style={{ padding: 20, overflow: "auto" }}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Skeleton active />
                    <br />
                  </div>
                ) : (
                  <Table
                    className="styledTable"
                    dataSource={loads}
                    columns={columns}
                    pagination={false}
                  />
                )}
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <p>{message}</p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    className="styledPagination"
                    onChange={(e) => handlePageChange(e)}
                    current={parseInt(paginationConfig.pageNumber)}
                    pageSize={paginationConfig.limit}
                    total={paginationConfig.totalDocs}
                    itemRender={itemRender}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: "Payments",
      children: (
        <Row align="middle" gutter={16}>
          <Col lg={24}>
            <div className="boxDetails">
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <h5 style={{ display: "inline", fontSize: 16 }}>Show : </h5>
                  <Select
                    size={"large"}
                    className="chartSelectBox"
                    defaultValue={paginationConfig.limit}
                    onChange={(e) => handleLimitChange(e)}
                    style={{
                      width: 70,
                      textAlign: "left",
                    }}
                    options={[
                      { value: 10, label: "10" },
                      { value: 20, label: "20" },
                      { value: 30, label: "30" },
                      { value: 40, label: "40" },
                      { value: 50, label: "50" },
                    ]}
                  />
                  &emsp;
                  <h5 style={{ display: "inline", fontSize: 16 }}>Entries</h5>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  &emsp;
                  <Popover
                    content={filterContent}
                    trigger="click"
                    open={openThree}
                    onOpenChange={(newOpen)=>{setOpenThree(newOpen)}}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <Button
                      style={{
                        padding: "10px 15px",
                        height: "auto",
                      }}
                      className="fltr-btn"
                    >
                      <FaFilter style={{ fontSize: "16px", color: "#000" }} />
                    </Button>
                  </Popover>
                </Col>
              </Row>

              <Row style={{ padding: 20, overflow: "auto" }}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Skeleton active />
                    <br />
                  </div>
                ) : (
                  <Table
                    className="styledTable"
                    dataSource={loads}
                    columns={columns}
                    pagination={false}
                  />
                )}
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <p>{message}</p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    className="styledPagination"
                    onChange={(e) => handlePageChange(e)}
                    current={parseInt(paginationConfig.pageNumber)}
                    pageSize={paginationConfig.limit}
                    total={paginationConfig.totalDocs}
                    itemRender={itemRender}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">My Loads</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={24}>
              <Card className="details-card table-card">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Myloads;
