import { useState } from "react";
import {
  Col,
  Row,
  Input,
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Space,
  AutoComplete,
} from "antd";
import { useNavigate } from "react-router";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { Get } from "../../../config/api/get";
import { STAY } from "../../../config/constants/api";
import AddressApi from "../../../config/api/map";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import swal from "sweetalert";

const { RangePicker } = DatePicker;

const ServicePage = () => {
  const token = useSelector((state) => state.user.userToken);
  const [options, setOptions] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [beds, setBeds] = useState(0);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  // const handlePageChange = (pageNumber) => {
  //   getAllRooms(pageNumber);
  // };
  // const onSelect = (value, options) => {
  //   setQuery(options?.label);
  //   AddressApi.getLatlngByAddress(value, (res) => {
  //     const { lat, lng } = res?.results[0]?.geometry?.location;
  //     setLongitude(lng);
  //     setLatitude(lat);
  //   });
  // };

  // const handleSearch = (text) => {
  //   if (text.length > 0) {
  //     AddressApi.getAddressPrediction(text, (data) => {
  //       if (Array.isArray(data?.predictions)) {
  //         const formattedOptions = data.predictions.map((prediction) => ({
  //           label: prediction.description,
  //           value: prediction.place_id,
  //         }));
  //         setOptions(formattedOptions);
  //       }
  //     });
  //   } else {
  //     setOptions([]);
  //   }
  // };

  const handleDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const handleMinusClick = (type) => {
    switch (type) {
      case "adults":
        adults >= 1 && setAdults(adults - 1);
        break;
      case "children":
        children >= 1 && setChildren(children - 1);
        break;
      case "beds":
        beds >= 1 && setBeds(beds - 1);
        break;
      default:
        break;
    }
  };

  const handlePlusClick = (type) => {
    switch (type) {
      case "adults":
        setAdults(adults + 1);
        break;
      case "children":
        setChildren(children + 1);
        break;
      case "beds":
        setBeds(beds + 1);
        break;
      default:
        break;
    }
  };
  const handleDone = () => {
    setDropdownVisible(false);
  };
  const handleDatesChange = (dates) => {
    setFrom(moment(dates[0]?.$d).format("YYYY-MM-DD"));
    setTo(moment(dates[1]?.$d).format("YYYY-MM-DD"));
  };
  const getAllRooms = (pageNumber, pageSize) => {
    let params = {
      page: pageNumber ? pageNumber.toString() : "1",
      limit: pageSize ? pageSize.toString() : "10",
      log: longitude !== "" ? longitude : null,
      lat: latitude !== "" ? latitude : null,
      startDate: from,
      endDate: to,
      beds: beds > 0 ? beds : null,
      guests: adults + children > 0 ? adults + children : null,
    };
    Get(STAY.getAllRooms, token, params)
      .then((response) => {
        if (response?.status) {
          navigate("/staySearchResult", {
            state: {
              rooms: response?.data?.docs,
              from: from,
              to: to,
              pageNumber: response?.data?.page,
              limit: response?.data?.limit,
              totalDocs: response?.data?.totalDocs,
              totalPages: response?.data?.totalPages,
              log: longitude,
              lat: latitude,
              beds: beds,
              guests: adults + children,
            },
          });
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message
        if(message){
          swal('Error', message, 'error')
        }
        console.log("Error fetching rooms", err);
      });
  };
  const handleSubmit = () => {
    if (
      !from &&
      !to &&
      longitude === "" &&
      latitude === "" &&
      adults === 0 &&
      beds === 0 &&
      children === 0
    ) {
      console.log('first')
      return;
    } else {
      getAllRooms();
    }
  };
  console.log(latitude, longitude)
  const menu = (
    <Menu>
      <Menu.Item>
        <Space>
          <span>Adults</span>
          <Button
            shape="circle"
            icon={<MinusOutlined />}
            onClick={() => handleMinusClick("adults")}
          />
          <Input type="number" min={1} value={adults} />
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => handlePlusClick("adults")}
          />
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space>
          <span>Children's</span>
          <Button
            shape="circle"
            icon={<MinusOutlined />}
            onClick={() => handleMinusClick("children")}
          />
          <Input type="number" min={1} value={children} />
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => handlePlusClick("children")}
          />
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space>
          <span>Beds</span>
          <Button
            shape="circle"
            icon={<MinusOutlined />}
            onClick={() => handleMinusClick("beds")}
          />
          <Input type="number" min={1} value={beds} />
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => handlePlusClick("beds")}
          />
        </Space>
      </Menu.Item>
      <Button
        type=""
        htmlType="submit"
        className="btn web-btn"
        style={{ width: "100%" }}
        onClick={handleDone}
      >
        Done
      </Button>
    </Menu>
  );

  const [address, setAddress] = useState("");

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = (newAddress) => {
    setAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLongitude(latLng?.lng);
        setLatitude(latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <section className="auth-banner">
      <Row justify="center" style={{ width: "100%", justifyContent: "center" }}>
        <Col lg={16}>
          <div className="auth-box">
            <h2 className="auth-heading">Find Your Next Stay</h2>
            <p className="auth-p">
              Search low prices on hotels, homes & much more...
            </p>
            <Row gutter={[16, 16]}>
              <Col md={6}>
               {/* <AutoComplete
                    options={options}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(value,options)=>{onSelect(value,options)}}
                    onSearch={(text) => {
                      handleSearch(text);
                      setQuery(text);
                    }}
                    value={query}
                    placeholder="Search Location here.."
                  /> */}
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    return (
                      <div className="location-drop">
                        <input
                          {...getInputProps({
                            placeholder: "Search location here...",
                            className: "location-search-input",
                            style: {
                              position :'relative',
                              width: "100%",
                              marginBottom: '10px',
                              height: '52px',
                              borderRadius: '6px',
                              border: '1px solid #E3E3E3',
                              backgroundColor: '#F8F8F8',
                              padding: '10px 50px 10px 10px',
                            },
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active googleSuggestion"
                              : "suggestion-item googleSuggestion";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                  padding:"5px 10px"
                                  
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                  padding:"5px 10px"
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </Col>
              <Col md={6}>
                <RangePicker
                  className="form-control web-input pe-2"
                  onChange={handleDatesChange}
                />
              </Col>
              <Col md={6}>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  visible={dropdownVisible}
                  onVisibleChange={handleDropdownVisibleChange}
                >
                  <Input
                    className="form-control web-input pe-2"
                    placeholder="2 Adults . 1 Children . 2 Beds"
                  />
                </Dropdown>
              </Col>
              <Col md={6}>
                <Button
                  type=""
                  htmlType="submit"
                  className="btn web-btn"
                  onClick={handleSubmit}
                  style={{ width: "100%" }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default ServicePage;
