import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Row, Col, Form, Input, Checkbox, InputNumber, Select } from "antd";

import countryList from "react-select-country-list";

const onChange = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};

function PersonalInformation(props, ref) {
  const [form] = Form.useForm();
  const options = useMemo(() => countryList().getData(), []);
  const [shipping, setShipping] = useState(false);
  const { validateFields } = form;

  const checkIsValid = async () => {
    try {
      let fieldNames = [
        "firstName",
        "lastName",
        "email",
        "phone",
        "billingAddress",
        "billingEmail",
        "billingPhone",
        "billingCountry",
        "billingCity",
        "billingState",
        "billingStreet",
        "billingZipCode",
      ];
      if (shipping) {
        fieldNames.push(
          "shippingAddress",
          "shippingEmail",
          "shippingPhone",
          "shippingCountry",
          "shippingCity",
          "shippingState",
          "shippingStreet",
          "shippingZipCode"
        );
      }
      const values = {};
      fieldNames.forEach((name) => {
        values[name] = form.getFieldValue(name);
      });
      const result = await validateFields(fieldNames, values);
      return { status: false, result: result };
    } catch (error) {
      return { status: true, result: error };
    }
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        async checkIsValid() {
          return await checkIsValid();
        },
      };
    },
    []
  );
  return (
    <div className="checkout">
      <h3>PERSONAL INFORMATION</h3>
      <Form
        layout="vertical"
        name="basic"
        className="loginForm"
        labelCol={{
          span: 0,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        form={form}
        // ref={ref}
        // onChange={checkIsValid}
      >
        <Row gutter={20}>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "First Name is Required",
                },
              ]}
            >
              <Input
                name="firsName"
                size="large"
                placeholder="Enter Full Name"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Last Name is Required",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Last Name"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please input valid email!",
                },
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Email Address"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone Number is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Phone Number"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} md={24} lg={24}>
            <h3>Billing Details</h3>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              label="Address"
              name="billingAddress"
              rules={[
                {
                  required: true,
                  message: "Billing Address is Required",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Address"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Email Address"
              name="billingEmail"
              rules={[
                {
                  type: "email",
                  message: "Please input valid Billing email!",
                },
                {
                  required: true,
                  message: "Please input your Billing email!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Email Address"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Phone Number"
              name="billingPhone"
              rules={[
                {
                  required: true,
                  message: "Billing Phone Number is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Phone Number"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Country"
              name="billingCountry"
              rules={[
                {
                  required: true,
                  message: "Select Billing Country",
                },
              ]}
            >
              <Select
                size="large"
                className=""
                showSearch
                placeholder="Country"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={options}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="City"
              name="billingCity"
              rules={[
                {
                  required: true,
                  message: "Enter Billing City",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter City"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="State"
              name="billingState"
              rules={[
                {
                  required: true,
                  message: "Enter Billing State",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter State"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Street"
              name="billingStreet"
              rules={[
                {
                  required: true,
                  message: "Enter Billing Street",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Street"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="ZIP Code"
              name="billingZipCode"
              rules={[
                {
                  required: true,
                  message: "Billing Zip Code is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Zipcode"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="ship"
              valuePropName="checked"
              style={{
                marginBottom: 0,
                color: "black",
                textAlign: "left",
              }}
            >
              <Checkbox
                checked={shipping}
                onChange={(e) => setShipping(e.target.checked)}
                style={{ marginBottom: 0, color: "#999999" }}
              >
                {" "}
                <p
                  className="fontFamily1"
                  style={{
                    margin: 0,
                    color: "#999999",
                    fontSize: "12px",
                  }}
                >
                  Ship To A Different Location
                </p>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {shipping && (
          <Row gutter={20}>
            <Col xs={24} md={24} lg={24}>
              <Form.Item
                label="Address"
                name="shippingAddress"
                rules={[
                  {
                    required: true,
                    message: "Shipping Address is Required",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Address"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Email Address"
                name="shippingEmail"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid Shipping email!",
                  },
                  {
                    required: true,
                    message: "Please input your Shipping email!",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Email Address"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Phone Number"
                name="shippingPhone"
                rules={[
                  {
                    required: true,
                    message: "Shipping Phone Number is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Enter Phone Number"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Country"
                name="shippingCountry"
                rules={[
                  {
                    required: true,
                    message: "Select Shipping Country",
                  },
                ]}
              >
                <Select
                  size="large"
                  className=""
                  showSearch
                  placeholder="Country"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="City"
                name="shippingCity"
                rules={[
                  {
                    required: true,
                    message: "Enter Shipping City",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter City"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="State"
                name="shippingState"
                rules={[
                  {
                    required: true,
                    message: "Enter Shipping State",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter State"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Street"
                name="shippingStreet"
                rules={[
                  {
                    required: true,
                    message: "Enter Shipping Street",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Street"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="ZIP Code"
                name="shippingZipCode"
                rules={[
                  {
                    required: true,
                    message: "Shipping Zip Code is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Enter Zipcode"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24}></Col>
          </Row>
        )}
      </Form>
    </div>
  );
}

export default forwardRef(PersonalInformation);
