import {
  Col,
  Row,
  Card,
  Button,
  Space,
  Pagination,
  Spin,
} from "antd";
import { useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { Get } from "../../config/api/get";
import { PRODUCT, UPLOADS_URL } from "../../config/constants/api";
import { useEffect, useState } from "react";
import { addToCart } from "../../redux/slice/cartSlice";
import Modals from "../../components/Modals";

const Shop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useSelector((state) => state.user.userToken);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const getAdminProducts = (pageNumber, pageSize) => {
    Get(PRODUCT.getAdminProducts, token, {
      page: pageNumber
      ? pageNumber.toString()
      : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
    })
    .then((response) => {
      if (response?.data?.docs) {
        setProducts(response?.data?.docs);
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error Fetching Products", err);
        setLoading(false);
      });
  };
  const handleAddToCart = (productName , productPrice , productImage, productId) =>{
    dispatch(addToCart({name : productName , price : productPrice, quantity : 1 , image : productImage, id : productId}))
    setIsOpen(true)
  }
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getAdminProducts(pageNumber);
    // getUsers(pageNumber);
  };
  const handleOk = () => {
    setIsOpen(false);
    navigate('/myCart')
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    getAdminProducts();
  }, []);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );

  const message = products ?  `Showing records ${endIndex} of ${paginationConfig.totalDocs}` : "Showing records 0 of 0";  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const { Meta } = Card;
  return (
    <div className="shop-page">
        <Modals
        open={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        text={'One item is Added to Cart Successfully.'}
        okText='Go to Cart'
        cancelText='Continue Shopping'
      />
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Products</h3>
              </div>
            </Col>
          </Row>
          {!loading && (
            <Row gutter={16}>
              {(Array.isArray(products) && products.length) > 0 ? (
                products.map((productData, index) => (
                  <Col xs={24} lg={6} key={index}>
                    <div>
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{
                          width: "100%",
                          padding: "8px",
                        }}
                      >
                        <div className="search-img">
                          <Card
                            className="card booking-card dropzone-card"
                            alt=""
                            cover={
                              <span
                                onClick={() =>
                                  // navigate("/shop/" + shopData[0].id)
                                  navigate("/shop/" + productData._id)
                                }
                              >
                                <img
                                  src={UPLOADS_URL + productData?.image}
                                  className="img-fluid"
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              </span>
                            }
                          >
                            <Meta title={productData?.title} />
                            <h6 className="booking-card-price">
                              ${productData?.price + ".00"}
                            </h6>
                            <Button
                          type="link"
                          className="web-btn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onClick={()=>{handleAddToCart(productData.title, productData.price, productData.image, productData._id)}}
                        >
                          Add To Cart
                        </Button>
                          </Card>
                        </div>
                      </Space>
                    </div>
                  </Col>
                ))
              ) : (
                <p
                  style={{
                    margin: "150px auto",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                >
                  No Products to show
                </p>
              )}
            </Row>
          )}
          {loading && (
            <Row justify={'center'} gutter={16} style={{width:'100%'}}>
              <Spin style={{margin:"100px 0px"}}/>
            </Row>
          )}

          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
              <p>{message}</p>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={(e) => handlePageChange(e)}
                current={parseInt(paginationConfig?.pageNumber)}
                pageSize={paginationConfig?.limit}
                total={paginationConfig?.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Shop;
