import React from "react";
import {
  Layout,
  Col,
  Row,
  Button,
  message,
} from "antd";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router";



function LoginAs() {
  const navigate = useNavigate();


  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background loginas-box">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={16}>
            <Row>
              <Col lg={12}>
                <div className="loginas-card">
                  <BiUserCircle className="icon--size-74" />
                  <h4>Login As user</h4>
                  <Button
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate("/login")}
                    className="web-btn"
                  >
                    Go <BsArrowRight className="go-icon" />
                  </Button>
                </div>
              </Col>

              <Col lg={12}>
                <div className="loginas-card">
                  <AiOutlineSetting className="icon--size-74" />
                  <h4>Login As Service Provide</h4>
                  <Button
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate("/login")}
                    className="web-btn"
                  >
                    Go <BsArrowRight className="go-icon" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default LoginAs;
