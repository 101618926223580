import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import { useNavigate } from "react-router";
import { FaArrowLeftLong, FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { Get } from "../../config/api/get";
import { JOB } from "../../config/constants/api";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { extractDate } from "../../config/constants";

const Careers = () => {
  const location = useLocation();
  const companyId = location?.state?.companyId;
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const [companyJobs, setCompanyJobs] = useState(null);
  const [loading, setLoading] = useState(true);
  const getAllJobs = () => {
    Get(`${JOB.getCompanyJobs}${companyId}`, token)
    .then((response) => {
      setCompanyJobs(response?.data?.docs);
      setLoading(false)
    })
    .catch((err) => {
      console.log("Error Fetcing Company Jobs", err);
      setLoading(false)
    });
  };
  useEffect(() => {
    getAllJobs();
  }, []);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Careers</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {!loading &&<Col lg={22}>
              {(Array.isArray(companyJobs) && companyJobs?.length > 0) ? (
                companyJobs.map((jobData, index) => (
                  <Card className="details-card" key={index}>
                    <Row style={{ alignItems: "center" }}>
                      <Col lg={19}>
                        <h4 className="text-26">{jobData?.title}</h4>
                        <div className="jump-inner-detail">
                          <div>
                            <h6>Location</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <FaLocationDot />{" "}
                              {jobData?.location?.street +
                                " " +
                                jobData?.location?.city}
                            </p>
                          </div>

                          <div>
                            <h6>Experience</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <GrCertificate /> {jobData?.experience + " years"}
                            </p>
                          </div>

                          <div>
                            <h6>Posted</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <FaClock /> {extractDate(jobData?.createdAt)}
                            </p>
                          </div>
                          <div>
                            <h6>Location</h6>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <FaLocationDot /> {jobData?.location?.city}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <Button
                          type="link"
                          className="web-btn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onClick={() =>
                            navigate("/dropzoneCompany/careers/" + jobData?._id)
                          }
                        >
                          View Details
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    margin: "150px 0px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  No Jobs Data to Show
                </p>
              )}
            </Col>}
            {loading && <Col
            lg={22}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "150px 0px",
            }}
          >
            <Spin />
          </Col>}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Careers;
