import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Space,
  Card,
  Drawer,
  Rate,
  Select,
  AutoComplete,
  Spin,
  Image,
} from "antd";
import { useLocation, useNavigate } from "react-router";
import { MdMenu } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Get } from "../../../config/api/get";
import { COMPANY, GIGS, UPLOADS_URL } from "../../../config/constants/api";
import { useSelector } from "react-redux";
import AddressApi from "../../../config/api/map";
import fb from "../../../assets/fb.png";
import inst from "../../../assets/inst.png";
import snap from "../../../assets/snap.png";
import { Link } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const DropzonecompanysFiltercards = ({ companyId }) => {
  const [options, setOptions] = useState([]);
  const [companyGigs, setCompanyGigs] = useState(null);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [jumpType, setJumpType] = useState("");
  const [query, setQuery] = useState("");
  const [address, setAddress] = useState("");
  const token = useSelector((state) => state.user.userToken);
  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = (value) => {
    setJumpType(value);
  };
  const handleLocChange = (newAddress) => {
    setAddress(newAddress);
  };
  const getCompanyGigs = (type, log, lat) => {
    let params = {
      type: type !== "" ? type : null,
      log: log !== "" ? log : null,
      lat: lat !== "" ? lat : null,
    };
    Get(`${GIGS.getCompanyGigs}${companyId}`, token, params)
      .then((response) => {
        setCompanyGigs(response?.data?.docs);
      })
      .catch((err) => {
        console.log("Error Fetching Company Gigs", err);
      });
  };
  const getCompanyProfile = () => {
    Get(`${COMPANY.getProfile}${companyId}`, token)
      .then((response) => {
        setCompanyProfile(response?.data);
      })
      .catch((err) => {
        console.log("Error fetching Company Profile", err);
      });
  };
  useEffect(() => {
    getCompanyGigs();
  }, []);
  useEffect(() => {
    getCompanyProfile();
  }, []);
  const { Meta } = Card;

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const toCamelCase = (text) => {
    return text
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
      .replace(/^[A-Za-z]/, (match) => match.toUpperCase());
  };
  // const onSelect = (value, options) => {
  //   setQuery(options?.label);
  //   AddressApi.getLatlngByAddress(value, (res) => {
  //     const { lat, lng } = res?.results[0]?.geometry?.location;
  //     setLongitude(lng);
  //     setLatitude(lat);
  //   });
  // };
  // const handleSearch = (text) => {
  //   if (text.length > 0) {
  //     AddressApi.getAddressPrediction(text, (data) => {
  //       if (Array.isArray(data?.predictions)) {
  //         const formattedOptions = data.predictions.map((prediction) => ({
  //           label: prediction.description,
  //           value: prediction.place_id,
  //         }));
  //         setOptions(formattedOptions);
  //       }
  //     });
  //   } else {
  //     setOptions([]);
  //   }
  // };
  const handleFilter = () => {
    if (longitude === "" && latitude === "" && jumpType === "") {
      return;
    } else {
      getCompanyGigs(jumpType, longitude, latitude);
    }
  };
  const handleClear = () => {
    setJumpType("");
    setLatitude("");
    setLongitude("");
    setQuery("");
    getCompanyGigs();
  };
  const handleSelect = (newAddress) => {
    setAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLongitude(latLng?.lng);
        setLatitude(latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24} xs={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">ABC Drop Zone</h3>
              </div>
            </Col>
            <Col lg={24} xs={24}>
              <div className="followSocial">
                <h3 className="">Follow us on social media:</h3>
                {companyProfile?.socialMedia && (
                  <div style={{ display: "flex", gap: "5px" }}>
                    {companyProfile?.socialMedia?.facebook && (
                      <a
                        href={`https://www.facebook.com/${companyProfile?.socialMedia?.facebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          preview={false}
                          alt={"Facebook"}
                          src={fb}
                          className=""
                        />
                      </a>
                    )}

                    {companyProfile?.socialMedia?.instagram && (
                      <a
                        href={`https://www.instagram.com/${companyProfile?.socialMedia?.instagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          preview={false}
                          alt={"Instagram"}
                          src={inst}
                          className=""
                        />
                      </a>
                    )}

                    {companyProfile?.socialMedia?.snapchat && (
                      <a
                        href={`https://www.snapchat.com/add/${companyProfile?.socialMedia?.snapchat}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          preview={false}
                          alt={"Snapchat"}
                          src={snap}
                          className=""
                        />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany" ? "active" : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
              >
                Join Load
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/signWaiver"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/signWaiver", {
                    state: { companyId: companyProfile?.dropzoneCompany },
                  })
                }
              >
                Sign Waiver
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/myLoads"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/myLoads", {
                    state: { companyId: companyProfile?.dropzoneCompany },
                  })
                }
              >
                My Loads
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/dzBriefing"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/dzBriefing", {
                    state: { companyProfile: companyProfile },
                  })
                }
              >
                DZ Briefing
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/calls" ? "active" : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/calls", {
                    state: { companyId: companyProfile?.dropzoneCompany },
                  })
                }
              >
                Calls
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/localWeather"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/localWeather", {
                    state: { companyProfile: companyProfile },
                  })
                }
              >
                Local Weather
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/pricing"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/pricing", {
                    state: { companyProfile: companyProfile },
                  })
                }
              >
                Pricing
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/careers"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/careers", {
                    state: { companyId: companyProfile?.dropzoneCompany },
                  })
                }
              >
                Careers
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/upcomingEvents"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/upcomingEvents", {
                    state: { companyId: companyProfile?.dropzoneCompany },
                  })
                }
              >
                Upcoming Events
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                className={`web-btn3 ${
                  location.pathname === "/dropzoneCompany/instructorsRiggers"
                    ? "active"
                    : ""
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0",
                }}
                onClick={() =>
                  navigate("/dropzoneCompany/instructorsRiggers", {
                    state: { companyId: companyProfile?.dropzoneCompany },
                  })
                }
              >
                Instructor & Riggers
              </Button>
            </Col>
          </Row>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={0} md={10} lg={7} xl={6}>
                <div className="left-div">
                  <div className="filter-heading">
                    <p>Filters</p>
                  </div>
                  <div className="inner-filter-box filter-canvas">
                    <strong>Search a Location</strong>
                    {/* <AutoComplete
                      options={options}
                      style={{
                        width: "100%",
                        margin: "10px 0px",
                      }}
                      onSelect={(value, options) => {
                        onSelect(value, options);
                      }}
                      onSearch={(text) => {
                        handleSearch(text);
                        setQuery(text);
                      }}
                      value={query}
                      placeholder="Search Location here.."
                    /> */}
                    <PlacesAutocomplete
                      value={address}
                      onChange={handleLocChange}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => {
                        return (
                          <div className="location-drop">
                            <input
                              {...getInputProps({
                                placeholder: "Search location here...",
                                className: "location-search-input",
                                style: {
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "10px",
                                  height: "52px",
                                  borderRadius: "6px",
                                  border: "1px solid #E3E3E3",
                                  backgroundColor: "#F8F8F8",
                                  padding: "10px 50px 10px 10px",
                                },
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active googleSuggestion"
                                  : "suggestion-item googleSuggestion";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                      padding: "5px 10px",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                      padding: "5px 10px",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }}
                    </PlacesAutocomplete>
                    <strong>Select Jump Type</strong>
                    <Select
                      defaultValue="Select"
                      onChange={handleChange}
                      value={jumpType}
                      style={{ width: "100%", margin: "10px 0px" }}
                      options={[
                        { value: "TANDEM", label: "Tandem" },
                        { value: "SOLO", label: "Solo" },
                        { value: "AFF", label: "Aff" },
                        { value: "", label: "Select" },
                      ]}
                    />

                    <div className="apply-btn">
                      <Button
                        type=""
                        htmlType="submit"
                        style={{
                          cursor: "pointer",
                        }}
                        className="web-btn"
                        onClick={handleFilter}
                      >
                        Search
                      </Button>
                    </div>
                    <div className="clear-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className=""
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#000" }}
                    onClick={showDrawer}
                  />
                  <Drawer placement="right" onClose={onClose} open={open}>
                    <div className="left-div">
                      <div className="filter-heading">
                        <p>Filters</p>
                      </div>
                      <div className="inner-filter-box filter-canvas">
                        <strong>Search a Location</strong>
                        {/* <AutoComplete
                          options={options}
                          style={{
                            width: "100%",
                            margin: "10px 0px",
                          }}
                          onSelect={(value, options) => {
                            onSelect(value, options);
                          }}
                          onSearch={(text) => {
                            handleSearch(text);
                            setQuery(text);
                          }}
                          value={query}
                          placeholder="Search Location here.."
                        /> */}
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleLocChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => {
                            return (
                              <div className="location-drop">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search location here...",
                                    className: "location-search-input",
                                    style: {
                                      position: "relative",
                                      width: "100%",
                                      margin: "10px 0px",
                                      height: "52px",
                                      borderRadius: "6px",
                                      border: "1px solid #E3E3E3",
                                      backgroundColor: "#F8F8F8",
                                    },
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active googleSuggestion"
                                      : "suggestion-item googleSuggestion";
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                          padding: "5px 10px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          padding: "5px 10px",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        </PlacesAutocomplete>
                        <strong>Select Jump Type</strong>
                        <Select
                          defaultValue="Select"
                          value={jumpType}
                          onChange={handleChange}
                          style={{ width: "100%", margin: "10px 0px" }}
                          options={[
                            { value: "TANDEM", label: "Tandem" },
                            { value: "SOLO", label: "Solo" },
                            { value: "AFF", label: "Aff" },
                            { value: "", label: "Select" },
                          ]}
                        />

                        <div className="apply-btn">
                          <Button
                            type=""
                            htmlType="submit"
                            style={{
                              cursor: "pointer",
                            }}
                            className="web-btn"
                            onClick={handleFilter}
                          >
                            Search
                          </Button>
                        </div>
                        <div className="clear-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className=""
                            onClick={handleClear}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>

              <Col xs={24} md={14} lg={17} xl={18}>
                <Row gutter={16}>
                  {Array.isArray(companyGigs) && companyGigs.length > 0 ? (
                    companyGigs.map((gig, index) => (
                      <Col xs={24} lg={8} key={index}>
                        <div>
                          <Space
                            direction="vertical"
                            size="middle"
                            style={{
                              width: "100%",
                              padding: "8px",
                            }}
                          >
                            <div className="search-img">
                              <Card
                                className="card booking-card dropzone-card"
                                cover={
                                  <span>
                                    <img
                                      src={UPLOADS_URL + gig?.image}
                                      alt=""
                                      className="img-fluid"
                                      style={{ width: "100%" }}
                                    />
                                  </span>
                                }
                              >
                                <Meta title={gig?.title} />
                                <p className="web-p">
                                  {toCamelCase(gig?.jumpType) +
                                    " Jump from 13,000 Ft"}
                                </p>
                                <h6 className="booking-card-price">
                                  ${gig?.charges}
                                </h6>
                                <Button
                                  type="link"
                                  className="web-btn"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                  onClick={() =>
                                    navigate("/dropZone/" + gig._id)
                                  }
                                >
                                  View Details
                                </Button>
                              </Card>
                              {gig?.averageRating && (
                                <div className="rating-box">
                                  <Rate
                                    allowHalf
                                    defaultValue={gig?.averageRating}
                                    disabled
                                  />
                                </div>
                              )}
                            </div>
                          </Space>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col
                      xs={23}
                      md={21}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "150px 0px",
                      }}
                    >
                      <Spin />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzonecompanysFiltercards;
