import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Post } from "../../config/api/post";
import { Put } from "../../config/api/put";
import {
  BOOKING,
  GIFT,
  JUMP,
  ORDER,
  PAYMENT,
} from "../../config/constants/api";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { clearCart } from "../../redux/slice/cartSlice";
import Modals from "../../../src/components/Modals";
import { Button } from "antd";
import moment from "moment";
import ReviewModal from "../../components/ReviewModal/index";

export default function CheckoutForm({ type, amount, ride }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const jumpData = useSelector((state) => state.data.jumpData);
  const orderData = useSelector((state) => state.data.orderData);
  const bookingData = useSelector((state) => state.data.bookingData);
  const giftData = useSelector((state) => state.data.giftData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => {
    setShow1(true);
  };
  const [showReviewModal, setShowReviewModal] = useState(false);
  const handleOkReviewModal = () => {
    setShowReviewModal(false);
    navigate('/');
  };
  const changeCouponStatus = () => {
    Put(`${GIFT.changeGiftStatus}${jumpData?.giftId}`, token, {
      status: "UTILIZED",
    })
      .then((response) => {
        if (response?.status) {
          console.log("success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const afterPayment = () => {
    switch (type) {
      case "ORDER": {
        let data = {
          customerName: orderData?.customerName,
          email: orderData?.email,
          phone: orderData?.phone,
          status: "PENDING",
          currency: orderData?.currency,
          products: orderData?.products,
          shippingAddress: orderData?.shippingAddress,
          billingAddress: orderData?.billingAddress,
        };
        Post(ORDER.addOrder, data, token)
          .then((response) => {
            if (response?.status) {
              dispatch(clearCart());
              setMessage("Payment succeeded 🎉");
              handleShow1();
            }
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          });
        break;
      }
      case "JUMP": {
        let data = {
          gig: jumpData?.gig,
          details: jumpData?.details,
          jumpType: jumpData?.jumpType,
          selectedDate: jumpData?.selectedDate,
          time: jumpData?.time,
          charges: jumpData?.charges,
          amenities: jumpData?.amenities,
          weather: "sunny",
        };
        Post(JUMP.bookJump, data, token)
          .then((response) => {
            if (response?.status) {
              setMessage("Payment succeeded 🎉");
              if (jumpData?.isUtilized) {
                changeCouponStatus();
              }
              handleShow1();
            }
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          });
        break;
      }
      case "BOOKING": {
        let data = {
          checkIn: bookingData?.checkIn,
          checkOut: bookingData?.checkOut,
          charges: bookingData?.charges,
          cleaningCharges: bookingData?.cleaningCharges,
          room: bookingData?.room,
          totalStay: bookingData?.totalStay,
        };
        Post(BOOKING.bookStay, data, token)
          .then((response) => {
            if (response?.status) {
              setMessage("Payment succeeded 🎉");
              handleShow1();
            }
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          });
        break;
      }
      case "GIFT": {
        let data = {
          amount: giftData?.amount,
          to: giftData?.to,
          from: giftData?.from,
          message: giftData?.message,
          deliveryDate: moment(giftData?.deliveryDate?.$d).format("YYYY-MM-DD"),
          couponCode: giftData?.couponCode,
          startDate: giftData?.startDate,
          expiryDate: giftData?.expiryDate,
        };
        Post(GIFT.addGift, data, token)
          .then((response) => {
            if (response.status) {
              setMessage("Payment succeeded 🎉");
              handleShow1();
            }
          })
          .catch((err) => {
            console.log(err);
            swal("Oops!", err.response?.data?.message, "error");
          });
        break;
      }
      case "RIDE": {
        setMessage("Payment succeeded 🎉");
        // handleShow1();
        setShowReviewModal(true)
      }
      default: {
        return;
      }
    }
  };
  const createCharge = async (stripeToken) => {
    let data = {
      paymentToken: stripeToken?.token,
      amount: amount,
      currency: "USD",
      //   payee: "658d4d7529f135eba69fcdc4",
      user: user?._id,
      // order: "659254646ea4ee7d7f17d736",
      //   type: "ORDER",
      type: type,
      jump: jumpData?.gig,
      // payee , order , type
    };
    if (type === "RIDE") {
      data.ride = ride._id;
    }
    Post(PAYMENT.stripeCharge, data, token)
      .then((response) => {
        if (response.status) {
          afterPayment();
        } else {
          setMessage("Payment failed");
        }
      })
      .catch((err) => {
        console.error(err);
        setMessage("An unexpected error occurred.");
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const cardElement = elements.getElement("card");
    stripe.createToken(cardElement).then((payload) => {
      setPaymentToken(payload);
      createCharge(payload);
    });

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment Status: " + paymentIntent.status + " 🎉");
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <CardElement />
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="web-btn"
          type="submit"
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      <Modals
        open={show1}
        handleOk={() => setShow1(false)}
        handleCancel={() => setShow1(false)}
        text="Your Payment has been made Successfully!"
        footer={[
          <Button
            key="submit"
            type=""
            className="web-btn"
            // onClick={() => navigate("/")}
            style={{ textAlign: "center" }}
          >
            Okay
          </Button>,
        ]}
      />
      <ReviewModal
        open={showReviewModal}
        handleOk={handleOkReviewModal}
        handleCancel={handleOkReviewModal}
        firstName={ride?.driver?.firstName}
        lastName={ride?.driver?.lastName}
        driver={ride?.driver?._id}
        // text="How was Your Experience"
      />
    </>
  );
}
