import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  Typography,
  Checkbox,
  Image,
  Select,
  DatePicker,
  Button,
  Form,
  Input,
  Spin,
  Space,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";
import moment from "moment";
import { Post } from "../../config/api/post";
import { SIGNWAIVER } from "../../config/constants/api";
import swal from "sweetalert";
import { Country, State, City } from "country-state-city";
const { Title, Paragraph } = Typography;

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const SignWaivers = () => {
  const token = useSelector((state) => state.user.userToken);
  const location = useLocation();
  const companyId = location?.state?.companyId;
  const [sign, setSign] = useState(null);
  const [secondSign, setSecondSign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signEmpty, setSignEmpty] = useState(true);
  const [secondSignEmpty, setSecondSignEmpty] = useState(true);
  const [signUrl, setSignUrl] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [countryIso, setCountryIso] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateIso, setStateIso] = useState("");
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStateName("");
    setStates(State.getStatesOfCountry(countryIso));
  }, [countryName]);
  useEffect(() => {
    setCityName("");
    setCitites(City.getCitiesOfState(countryIso, stateIso));
  }, [stateName]);
  const onFinish = (values) => {
    setLoading(true);
    let postData = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      phone: values?.phone,
      gender: values?.gender,
      uspaNo: values?.uspaNo,
      licenseNo: values?.licenseNo,
      dateOfBirth: moment(values?.dateOfBirth?.$d).format("YYYY-MM-DD"),
      email: values?.email,
      signature: signUrl,
      dropzoneCompany: companyId,
      emergency: {
        firstName: values?.emfirstName,
        lastName: values?.emlastName,
        phone: values?.emphone,
      },
      address: {
        lineOne: values?.lineOne,
        lineTwo: values?.lineTwo,
        state: stateName,
        city: cityName,
        country: countryName,
        zipCode: values?.zipCode,
      },
    };
    Post(SIGNWAIVER.approvePolicy, postData, token)
      .then((response) => {
        if (response.status) {
          setLoading(false);
          form.resetFields();
          sign?.clear();
          secondSign?.clear();
          setSign(null);
          setSecondSign(null);
          setAgreements({
            agreeOne: false,
            agreeTwo: false,
            agreeThree: false,
            agreeFour: false,
          });
          swal(
            "Success",
            "Agreed to Signwaiver Policy Successfully",
            "success"
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
        swal("Error", err.response.data.message, "error");
      });
  };
  const handleChange = (fieldName, value, e) => {
    switch (fieldName) {
      case "City":
        setCityName(value);
        break;
      case "Country":
        const { name, isoCode } = JSON.parse(value);
        setCountryName(name);
        setCountryIso(isoCode);
        break;
      case "State":
        setStateName(value);
        setStateIso(e?.key);
        break;
      default:
        return;
    }
  };
  const checkDisabled = (obj) => {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        continue;
      }
      if (!obj[key] || obj[key] === undefined || obj[key] === null) {
        return true;
      }
    }
    return false;
  };

  const [agreements, setAgreements] = useState({
    agreeOne: false,
    agreeTwo: false,
    agreeThree: false,
    agreeFour: false,
  });

  const initialAgreeData = {
    agreeOne: agreements.agreeOne,
    agreeTwo: agreements.agreeTwo,
    agreeThree: agreements.agreeThree,
    agreeFour: agreements.agreeFour,
  };

  const handleClear = (state) => {
    if (state === "sign") {
      sign.clear();
      setSign(null);
    } else {
      secondSign.clear();
      setSecondSign(null);
    }
  };

  const navigate = useNavigate();

  const handleAgreementChange = (e) => {
    setAgreements((prevState) => ({
      ...prevState,
      [e.target.name]: !agreements[e.target.name],
    }));
  };

  const handleSignEnd = () => {
    setSignEmpty(sign?.isEmpty());
    setSecondSignEmpty(secondSign?.isEmpty());
    setSignUrl(sign?.getTrimmedCanvas().toDataURL("image/png"));
  };

  const [form] = Form.useForm();

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Sign Waivers</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={19}>
              <Form
                className="row g-3"
                name="basic"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Card className="details-card">
                  <Row align="middle" gutter={16}>
                    <Col lg={24}>
                      <Paragraph>
                        <span className="web-p2">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the when an
                          unknown printer took a galley of type and scrambled it
                          to make a type specimen book. It has survived not only
                          five centuries, but also the leap into electronic
                          typesetting, remaining essentially unchanged. It was
                          popularised in the 1960s with the release of Letraset
                          sheets containing Lorem Ipsum passages, and more
                          recently with desktop publishing software like Aldus
                          PageMaker including versions of Lorem Ipsum. Lorem
                          Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the when an
                          unknown printer took a galley of type and scrambled it
                          to make a type specimen book. It has survived not only
                          five centuries, but also the leap into electronic
                          typesetting, remaining essentially unchanged.
                        </span>
                        <br />
                        <br />
                        <span className="web-p2">
                          1- It was popularised in the with the release of
                          Letraset sheets containing Lorem Ipsum passages, and
                          more recently with desktop publishing software like
                          Aldus PageMaker including versions of Lorem Ipsum.
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since.
                        </span>
                      </Paragraph>

                      <Checkbox
                        id="inlineCheckbox2"
                        className="form-check-input"
                        onChange={handleAgreementChange}
                        value={agreements.agreeOne}
                        checked={agreements.agreeOne}
                        name="agreeOne"
                      >
                        I Agree
                      </Checkbox>

                      <Paragraph className="web-p2">
                        2- Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the when an
                        unknown printer took a galley of type and scrambled it
                        to make a type specimen book. It has survived not only
                        five centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages.
                      </Paragraph>

                      <Checkbox
                        id="inlineCheckbox3"
                        className="form-check-input"
                        onChange={handleAgreementChange}
                        value={agreements.agreeTwo}
                        checked={agreements.agreeTwo}
                        name="agreeTwo"
                      >
                        I Agree
                      </Checkbox>

                      <Paragraph className="web-p2">
                        3- Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the when an
                        unknown printer took a galley of type and scrambled it
                        to make a type specimen book. It has survived not only
                        five centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages. It was
                        popularised in the with the release of Letraset sheets
                        containing Lorem Ipsum passages, and more recently with
                        desktop publishing software like Aldus PageMaker
                        including versions of Lorem Ipsum. Lorem Ipsum is simply
                        dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since
                      </Paragraph>

                      <Checkbox
                        id="inlineCheckbox4"
                        className="form-check-input"
                        onChange={handleAgreementChange}
                        value={agreements.agreeThree}
                        checked={agreements.agreeThree}
                        name="agreeThree"
                      >
                        I Agree
                      </Checkbox>

                      <Paragraph className="web-p2">
                        4- Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the when an
                        unknown printer took a galley of type and scrambled it
                        to make a type specimen book. It has survived not only
                        five centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages.
                      </Paragraph>

                      <Title level={5} className="text-16">
                        Your Sign Here
                      </Title>

                      <div className="sign-box">
                        <SignatureCanvas
                          penColor="green"
                          canvasProps={{
                            width: 1300,
                            height: 200,
                            className: "sigCanvas",
                          }}
                          ref={(data) => setSign(data)}
                          onEnd={handleSignEnd}
                        />
                      </div>
                      <Button
                        type=""
                        className="btn web-btn px-5"
                        style={{
                          minWidth: "35px",
                          height: "35px",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          handleClear("sign");
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Card>

                <Card className="details-card">
                  <Row align="middle" gutter={16}>
                    <Col lg={24}>
                      <h4 class="text-22">Participant’s Information</h4>

                      <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your first name!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter First Name"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                              {
                                type: "text",
                                message: "Enter Last Name",
                              },
                              {
                                required: true,
                                message: "Please enter Last Name",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Last Name"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Phone Number"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your phone number!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Phone Number"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select a user type!",
                              },
                            ]}
                          >
                            <Select placeholder="Select">
                              <Select.Option value="MALE">Male</Select.Option>
                              <Select.Option value="FEMALE">
                                Female
                              </Select.Option>
                              <Select.Option value="OTHERS">
                                Others
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="DOB"
                            name="dateOfBirth"
                            rules={[
                              {
                                required: true,
                                message: "Enter DOB",
                              },
                            ]}
                          >
                            <DatePicker
                              className="web-input"
                              style={{ width: "100%", paddingRight: "10px" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="USPA Member #"
                            name="uspaNo"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your USPA Member #!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter USPA Member #"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="License Number"
                            name="licenseNo"
                            rules={[
                              {
                                required: true,
                                message: "Enter License Number",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter License Number*"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <h4 class="text-22">PARTICIPANT’S SIGNATURE</h4>
                      <div className="sign-box">
                        <SignatureCanvas
                          penColor="green"
                          canvasProps={{
                            width: 1300,
                            height: 200,
                            className: "sigCanvas",
                          }}
                          onEnd={handleSignEnd}
                          ref={(data) => setSecondSign(data)}
                        />

                        {/* <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={sign}
                        className=""
                      /> */}
                      </div>
                      <Button
                        type=""
                        className="btn web-btn px-5"
                        style={{
                          minWidth: "35px",
                          height: "35px",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          handleClear("secondSign");
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Card>

                <Card className="details-card">
                  <Row align="middle" gutter={16}>
                    <Col lg={24}>
                      <h4 class="text-22">PARTICIPANT’S ADDRESS</h4>

                      <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Address Line 1"
                            name="lineOne"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your Address Line 1!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Address Line 1"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Address Line 2"
                            name="lineTwo"
                            rules={[
                              {
                                required: true,
                                message: "Enter Address Line 2",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Address Line 2"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item label="Country" name="country">
                            <Space wrap className="filter-select">
                              <Select
                                size="large"
                                defaultValue="Select"
                                showSearch
                                placeholder="Country"
                                optionFilterProp="children"
                                onChange={(val, e) => {
                                  handleChange("Country", val, e);
                                }}
                                filterOption={(input, option) =>
                                  option.value
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={countries?.map((country) => ({
                                  value: JSON.stringify({
                                    name: country.name,
                                    isoCode: country.isoCode,
                                  }),
                                  label: country?.name,
                                }))}
                              />
                            </Space>
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item label="city">
                            <Space wrap className="filter-select">
                              <Select
                                defaultValue="Select"
                                onChange={(val, e) => {
                                  handleChange("City", val, e);
                                }}
                                value={cityName}
                                style={{ width: "100%" }}
                              >
                                {cities.map((cityObj) => (
                                  <Select.Option
                                    key={cityObj?.isoCode}
                                    value={cityObj?.name}
                                  >
                                    {cityObj?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Space>
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item label="state">
                            <Space wrap className="filter-select">
                              <Select
                                defaultValue="Select"
                                onChange={(val, e) => {
                                  handleChange("State", val, e);
                                }}
                                value={stateName}
                                style={{ width: "100%" }}
                              >
                                {states.map((stateObj) => (
                                  <Select.Option
                                    key={stateObj?.isoCode}
                                    value={stateObj?.name}
                                  >
                                    {stateObj?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Space>
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Zipcode"
                            name="zipCode"
                            rules={[
                              {
                                required: true,
                                message: "Enter Zipcode",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Zipcode"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={24}>
                          <h4 class="text-22">EMAIL ADDRESS</h4>
                        </Col>

                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your Email Address!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Email Address"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Confirm Email Address"
                            name="confirmemailaddress"
                            rules={[
                              {
                                required: true,
                                message: "Enter Confirm Email Address",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Confirm Email Address"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col> */}
                        <Col lg={24}>
                          <Checkbox
                            id="inlineCheckbox8"
                            className="form-check-input"
                          >
                            Check to receive information, news & discounts by
                            email
                          </Checkbox>
                        </Col>
                        <Col lg={24}>
                          <h4 class="text-22">Emergency Contact</h4>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="First Name"
                            name="emfirstName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your First Name!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter First Name"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Last Name"
                            name="emlastName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your Last Name!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Last Name"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} xs={24}>
                          <Form.Item
                            label="Emergency Phone Number"
                            name="emphone"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your Emergency Phone Number",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Emergency Phone Number"
                              className="web-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24}>
                          <h4 class="text-22">Electronic Signature Consent</h4>
                        </Col>
                        <Col lg={24}>
                          <Checkbox
                            id="inlineCheckbox9"
                            className="form-check-input"
                            onChange={handleAgreementChange}
                            value={agreements.agreeFour}
                            checked={agreements.agreeFour}
                            name="agreeFour"
                          >
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the when
                            an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages.
                          </Checkbox>
                        </Col>
                        <div className="" style={{ textAlign: "center" }}>
                          <Button
                            type=""
                            htmlType="submit"
                            className={`btn ${
                              checkDisabled(initialAgreeData) ||
                              secondSignEmpty ||
                              signEmpty
                                ? "web-btn3"
                                : "web-btn"
                            } px-5`}
                            style={
                              checkDisabled(initialAgreeData) ||
                              secondSignEmpty ||
                              signEmpty
                                ? {
                                    cursor: "not-allowed",
                                    pointerEvents: "none",
                                    backgroundColor: loading ? "white" : "",
                                  }
                                : {
                                    /* Apply your regular button styles here */
                                    backgroundColor: loading ? "white" : "",
                                  }
                            }
                            disabled={
                              checkDisabled(initialAgreeData) ||
                              secondSignEmpty ||
                              signEmpty
                            }
                          >
                            {loading ? <Spin /> : "Agree to this Document"}
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SignWaivers;
