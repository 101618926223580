
import { Layout } from "antd";
import StayReservationInfo from "./stayReservationInfo";


function DropZone() {

  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
      <StayReservationInfo />
    </Layout>
  );
}

export default DropZone;
