import { useEffect, useState } from "react";
import { Col, Row, Card, Rate } from "antd";
import { useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation } from "react-router";
import { UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";

const Pricing = () => {
  const token = useSelector((state) => state.user.userToken);
  const location = useLocation();
  const companyProfile = location.state.companyProfile;
  const navigate = useNavigate();
  const [pricing, setPricing] = useState(null);
  const [image, setImage] = useState(null);
  const getCompanyPricing = () => {
    setImage(companyProfile?.image);
    setPricing(companyProfile?.pricing);
  };
  useEffect(() => {
    getCompanyPricing();
  }, []);
  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">pricing</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={19}>
              <Card className="details-card">
                <Row align="middle" gutter={16}>
                  <Col lg={10}>
                    <div
                      className="one-product"
                      style={{ paddingRight: "30px" }}
                    >
                      <div className="search-img">
                        <div>
                          {image && (
                            <span>
                              <img
                                src={UPLOADS_URL + image}
                                alt=""
                                className="img-fluid"
                                style={{ width: "100%" }}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={10}>
                    {pricing && (
                      <div
                        className="details-card price-summary-card"
                        style={{ marginBottom: "0" }}
                      >
                        <div className="price-summary-detail">
                          <p>Tandem Jump</p>
                          <p>${pricing.tandemJump}</p>
                        </div>
                        <div className="price-summary-detail">
                          <p>Solo Jump</p>
                          <p>${pricing.soloJump}</p>
                        </div>
                        <div className="price-summary-detail">
                          <p>AFF Jump</p>
                          <p>${pricing.affJump}</p>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Pricing;
