import React from "react";

const LocalWeather = () => {
  return (
    <div style={{ display: "flex", alignItems: "center" , justifyContent:"center" , height:"50vh" }}>
      <p style={{fontSize:'22px' , fontWeight:'bold'}}>API to be provided by the Client.</p>
    </div>
  );
};

export default LocalWeather;
