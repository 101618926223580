import React from "react";
// import AuthLayout from "../../components/";
import { Layout, Input } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import FindCar from "../../views/ridesBooking/findCar";

// import router from "next/router";

function ServiceProvidersPrivateEvent() {
  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
    <FindCar/>

    </Layout>
  );
}

export default ServiceProvidersPrivateEvent;
