import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Button, Typography, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { upcomingEventsData } from "../../../components/Data/data";
import { Get } from "../../../config/api/get";
import { EVENT } from "../../../config/constants/api";
// const { Meta } = Card;
const { Title } = Typography;
const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric", timeZone: "UTC" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};
const UpcomingEvents = () => {
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const getEventDetails = () => {
    Get(`${EVENT.getevent}${id}`, token)
    .then((response) => {
      setEventDetails(response?.data);
      setLoading(false);
    })
      .catch((error) => {
        console.error("Error fetching event details:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getEventDetails();
  }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Event Details</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {!loading ? (
              <Col lg={22}>
                {eventDetails && (
                  <Card className="details-card">
                    <Row align="middle" gutter={24}>
                      <Col lg={24}>
                        <div
                          className=" event-date-box"
                          style={{ textAlign: "center", marginBottom: "20px" }}
                        >
                          <h6>
                            {dateConverter(eventDetails?.date)
                              .split(" ")
                              .map((part, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && <br />} {part}
                                </React.Fragment>
                              ))}
                          </h6>
                        </div>
                      </Col>
                      <Col lg={24}>
                        <div style={{ textAlign: "center" }}>
                          <h5 className="text-22-urb">{eventDetails?.title}</h5>
                          <p className="web-p">{eventDetails?.description}</p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            ) : (
              <Col
                lg={22}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "150px 0px",
                }}
              >
                <Spin />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
