import React, { useState, useRef } from "react";
import { Button, message, Steps, Row, Col } from "antd";
import OrderSummary from "../../components/orderSummary";
import {
  AiFillIdcard,
  AiFillCreditCard,
  AiFillCheckSquare,
} from "react-icons/ai";
import PersonalInformation from "./personalInformation";
import Payment from "../../views/Payment";
import ConfirmOrderDetails from "./confirmOrderDetails";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { addData } from "../../redux/slice/afterPaymentSlice";

const steps = [
  {
    title: "Address",
    content: "First-content",
    icon: <AiFillIdcard />,
  },
  {
    title: "Payment",
    content: "Second-content",
    icon: <AiFillCreditCard />,
  },
  // {
  //   title: "Confirm",
  //   content: "Last-content",
  //   icon: <AiFillCheckSquare />,
  // },
];
function CheckOut() {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const user = useSelector((state) => state.user.userData);
  const location = useLocation();
  const subTotal = location?.state?.subTotal;
  const [current, setCurrent] = useState(0);
  const checkRef = useRef(null);

  const next = async () => {
    if (current === 0) {
      let isValid = await checkRef?.current?.checkIsValid();
      if (isValid.status) {
        swal("Error", isValid?.result?.errorFields[0]?.errors[0], "error");
        return;
      } else {
        const { result } = isValid;
        const orderData = {
          customerName: result?.firstName + result?.lastName,
          email: result?.email,
          phone: result?.phone,
          totalPrice: result?.subTotal,
          currency: "$",
          customer: user._id,
          billingAddress: {
            address: result?.billingAddress,
            city: result?.billingCity,
            country: result?.billingCountry,
            state: result?.billingState,
            street: result?.billingStreet,
            zipCode: result?.billingZipCode,
          },
          shippingAddress: {
            address: result?.shippingAddress
              ? result?.shippingAddress
              : result?.billingAddress,
            city: result?.shippingCity
              ? result?.shippingCity
              : result?.billingCity,
            country: result?.shippingCountry
              ? result?.shippingCountry
              : result?.billingCountry,
            state: result?.shippingState
              ? result?.shippingState
              : result?.billingState,
            street: result?.shippingStreet
              ? result?.shippingStreet
              : result?.billingStreet,
            zipCode: result?.shippingZipCode
              ? result?.shippingZipCode
              : result?.billingZipCode,
          },
          products: cartItems.map((item) => ({
            name: item?.name,
            quantity: item?.quantity,
            product: item?.id,
            price: item?.price,
          })),
        };
        dispatch(addData({ type: "ORDER", data: orderData }));
      }
    }
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  return (
    <>
      <Row justify="center" className="whitebg steps">
        <Col xs={22} md={20} xl={19}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Checkout</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center" gutter={50} className="padding-y-40 tnc">
            <Col xs={24} md={22} lg={22}>
              <Row gutter={30}>
                <Col xs={24} md={15} lg={17}>
                  <div className="details-card">
                    <Steps
                      current={current}
                      items={items}
                      labelPlacement="vertical"
                    />
                    {current === 0 && <PersonalInformation ref={checkRef} />}
                    {current === 1 && (
                      <Payment ptype="ORDER" ptotal={subTotal} />
                    )}
                    {/* {current === 2 && <ConfirmOrderDetails />} */}
                    <div
                      style={{
                        marginTop: 24,
                      }}
                    >
                      {current < steps.length - 1 && current !== 1 && (
                        <Button type="" onClick={next} className="web-btn">
                          Next
                        </Button>
                      )}

                      {current > 0 && (
                        <Button
                          style={{
                            margin: "0 8px",
                          }}
                          onClick={() => prev()}
                          className="web-btn"
                        >
                          Previous
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={9} lg={7}>
                  <OrderSummary subTotal={subTotal} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CheckOut;
