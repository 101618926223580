import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Carousel, Badge, Image, Space } from "antd";
import { BOOKING } from "../../../config/constants/api";
import { bookingDate } from "../../../components/Data/data";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ClockCircleFilled,
  CalendarFilled,
  StopOutlined,
} from "@ant-design/icons";
import { Get } from "../../../config/api/get";
import { UPLOADS_URL } from "../../../config/constants/api";
const { Meta } = Card;
const HomeBookingSlider = () => {
  const [bookingData, setBookingData] = useState(null);
  const getMyBookings = () => {
    Get(BOOKING.mybookings, token)
      .then((response) => {
        setBookingData(response.data?.docs);
      })
      .catch((err) => {
        console.error("Error Fetching Booking Details", err);
      });
  };
  useEffect(() => {
    getMyBookings();
  }, []);
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const formatTime = (dateTimeString) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    };
    const time = new Date(dateTimeString).toLocaleTimeString("en-US", options);
    return time;
  };
  const navigate = useNavigate();
  const carouselSettings = {
    dots: true,
    // infinite: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200, // Medium screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992, // Small screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Extra small screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575, // Extra small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const token = useSelector((state) => state.user.userToken);
  return (
    <section className="home-booking-slider">
      <div>
        {!token ? (
          <></>
        ) : (
          <div className="container-fluid">
            <Row justify="center" align="middle" className="text-center">
              <Col lg={24}>
                <h6 className="text-16" style={{ textAlign: "center" }}>
                  popular choices
                </h6>
                <h2 className="text-45" style={{ textAlign: "center" }}>
                  My Bookings
                </h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col lg={20}>
                {Array.isArray(bookingData) ? (
                  <Carousel className="booking" {...carouselSettings}>
                    {bookingData?.map((data, index) => {
                      return (
                        <>
                          <div key={index} className="item">
                            <div>
                              <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                }}
                              >
                                <Badge.Ribbon
                                  text={data?.status}
                                  color={
                                    data?.status === "COMPLETED"
                                      ? "#00B31D"
                                      : data?.status === "UPCOMING"
                                      ? "#DD9F00"
                                      : data?.status === "ONGOING"
                                      ? "#2D308B"
                                      : "red"
                                  }
                                  placement="start"
                                >
                                  <Card
                                    className="booking-card"
                                    cover={
                                      <span>
                                        <img
                                          src={
                                            UPLOADS_URL +
                                            data?.roomDetails[0]?.image
                                          }
                                          alt=""
                                          className="img-fluid"
                                          style={{ width: "100%" }}
                                        />
                                      </span>
                                    }
                                  >
                                    <Meta
                                      title={data?.roomTitle}
                                      description={
                                        <>
                                          <div className="booking-card-span">
                                            <span>
                                              <ClockCircleFilled />{" "}
                                              {formatTime(data?.checkIn)}
                                            </span>
                                            <span>
                                              <CalendarFilled />{" "}
                                              {formatDate(data?.checkIn)}
                                            </span>
                                            <span>
                                              <StopOutlined />{" "}
                                              {data?.totalStay.toString()
                                                .length < 2
                                                ? "0" +
                                                  data?.totalStay +
                                                  " days"
                                                : data?.totalStay + " days"}
                                            </span>
                                          </div>
                                          <h6 className="booking-card-price">
                                            {"$" + data?.totalPayable + "/paid"}
                                          </h6>
                                          <Button
                                            type="link"
                                            className="web-btn"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                            onClick={() =>
                                              navigate(
                                                "/mystayBookings/" + data._id
                                              )
                                            }
                                          >
                                            View Details
                                          </Button>
                                        </>
                                      }
                                    />
                                  </Card>
                                </Badge.Ribbon>
                              </Space>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                ) : (
                  <p
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      textAlign:'center',
                      minWidth:'100%',
                    }}
                  >
                    No Bookings to Show
                  </p>
                )}
                <div style={{ textAlign: "center" }}>
                  <span
                    className="blue-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/mystayBookings")}
                  >
                    View All
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeBookingSlider;
