import { Col, Row, Card, Typography, Steps, Button, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useEffect, useMemo, useState } from "react";
import { Post } from "../../../config/api/post";
import { RIDE } from "../../../config/constants/api";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  DistanceMatrixService,
} from "@react-google-maps/api";
const { Step } = Steps;
const { Title } = Typography;

const Pricing = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [dataFetched, setDataFetched] = useState(false);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [price, setPrice] = useState(null);
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const pickupLocation = location?.state?.pickupLocation;
  const destinationLocation = location?.state?.destinationLocation;
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const pickupCoordinates = useMemo(
    () => ({
      lat: pickupLocation.coordinates[1],
      lng: pickupLocation.coordinates[0],
    }),
    [pickupLocation]
  );

  const dropoffCoordinates = useMemo(
    () => ({
      lat: destinationLocation.coordinates[1],
      lng: destinationLocation.coordinates[0],
    }),
    [destinationLocation]
  );
  const loaderOptions = useMemo(
    () => ({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyBXqV9bSEkfm5Wh7OQMj37V-n3F4AiyE40",
      libraries: ["places"],
    }),
    []
  );
  const { isLoaded } = useJsApiLoader(loaderOptions);

  useEffect(() => {
    if (!pickupLocation || !destinationLocation) {
      navigate(-1);
    }
  }, []);
  useEffect(() => {
    if (pickupCoordinates && dropoffCoordinates && !dataFetched) {
      const fetchDirectionsAndDistance = async () => {
        try {
          // Ensure DirectionsService and DistanceMatrixService are initialized
          if (window.google && window.google.maps) {
            // const directionsService = new window.google.maps.DirectionsService();
            const distanceMatrixService =
              new window.google.maps.DistanceMatrixService();
            // Fetch Directions
            // directionsService.route(
            //   {
            //     origin: pickupCoordinates,
            //     destination: dropoffCoordinates,
            //     travelMode: window.google.maps.TravelMode.DRIVING,
            //   },
            //   (result, status) => {
            //     if (status === window.google.maps.DirectionsStatus.OK) {
            //       setDirectionsResponse(result);
            //     } else {
            //       console.error(`Error fetching directions: ${status}`);
            //       message.error(`Error fetching directions: ${status}. Please try again.`);
            //     }
            //   }
            // );

            // Fetch Distance Matrix
            distanceMatrixService.getDistanceMatrix(
              {
                origins: [pickupCoordinates],
                destinations: [dropoffCoordinates],
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (response, status) => {
                if (status === window.google.maps.DistanceMatrixStatus.OK) {
                  const distanceData = response.rows[0]?.elements[0];
                  console.log(distanceData, "distanceData");
                  if (
                    distanceData &&
                    distanceData.distance &&
                    distanceData.duration
                  ) {
                    const distanceInKm = parseFloat(
                      distanceData.distance.text
                        .replace(" km", "")
                        .replace(",", "")
                    );
                    const distanceInMiles = distanceInKm * 0.621371;
                    const calculatedPrice = distanceInMiles * 3;
                    setDistance(distanceData.distance.text);
                    setDuration(distanceData.duration.text);
                    setPrice(calculatedPrice.toFixed(2));
                  } else {
                    console.log("Distance data is missing in the response.");
                    message.error("Distance data is missing in the response.");
                  }
                } else {
                  console.log(`Error fetching distance matrix: ${status}`);
                  message.error(
                    `Error fetching distance matrix: ${status}. Please try again.`
                  );
                }
              }
            );
            // distanceMatrixService.getDistanceMatrix(
            //   {
            //     origins: [pickupCoordinates],
            //     destinations: [dropoffCoordinates],
            //     travelMode: window.google.maps.TravelMode.DRIVING,
            //   },
            //   (response, status) => {
            //     if (status === window.google.maps.DistanceMatrixStatus.OK) {
            //       console.log("Distance Matrix Response:", response);
            //       const distanceData = response.rows[0]?.elements[0];
            //       console.log(distanceData, "distanceData");
            //       if (distanceData && distanceData.distance && distanceData.duration) {
            //         setDistance(distanceData.distance.text);
            //         setDuration(distanceData.duration.text);
            //       } else {
            //         console.error("Distance data is missing in the response.");
            //         message.error("Distance data is missing in the response.");
            //       }
            //     } else {
            //       console.error(`Error fetching distance matrix: ${status}`);
            //       message.error(`Error fetching distance matrix: ${status}. Please try again.`);
            //     }
            //   }
            // );

            setDataFetched(true); // Mark data as fetched
          } else {
            console.log("Google Maps API is not loaded.");
            message.error(
              "Google Maps API is not loaded. Please try again later."
            );
          }
        } catch (error) {
          console.log("Error fetching directions or distance matrix:", error);
          message.error("Error fetching data. Please try again.");
        }
      };

      fetchDirectionsAndDistance();
    }
  }, [pickupCoordinates, dropoffCoordinates, dataFetched]);
  const requestRide = async () => {
    let data = {
      passengerName: user?.firstName + " " + user?.lastName,
      pickupLocation: {
        type: "Point",
        address: pickupLocation?.address,
        coordinates: pickupLocation?.coordinates,
      },
      dropoffLocation: {
        type: "Point",
        address: destinationLocation?.address,
        coordinates: destinationLocation?.coordinates,
      },
      contact: user?.mobile,
      fare: price,
      distance,
      duration,
    };
    Post(RIDE.bookRide, data, token)
      .then((response) => {
        if (response?.status) {
          swal("System Alert", response?.message, "success");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Book a Ride</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={22}>
              <Card className="map-bg">
                <Row align="middle" gutter={16}>
                  <Col lg={24}>
                    <div className="details-card">
                      <div className="stepper d-flex flex-column mt-4 ml-2">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={0}
                          className="mb-1"
                        >
                          <Step
                            title="Pickup Location"
                            // icon={<EnvironmentOutlined />}
                            // description="Abc road, 123 Street New York"
                            description={
                              pickupLocation?.address?.length < 50
                                ? pickupLocation?.address
                                : pickupLocation?.address.slice(0, 50) + "..."
                            }
                          />
                          <Step
                            title="Destination"
                            // icon={<EnvironmentOutlined />}
                            // description="4264 Nelm Street, 123 Street Chicago"
                            description={
                              destinationLocation?.address?.length < 50
                                ? destinationLocation?.address
                                : destinationLocation?.address.slice(0, 50) +
                                  "..."
                            }
                          />
                        </Steps>
                        <div className="text-center mt-3">
                          <Button
                            type=""
                            className="web-btn"
                            onClick={requestRide}
                            // onClick={() => navigate("/driverCarArrival")}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Pricing;
