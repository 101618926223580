const { NODE_ENV } = process.env;
const { hostname } = window.location;

const servers = {
  local: "http://localhost:3016",
  customDev: "https://react.customdev.solutions:3016",
  live: "https://api.skydivebnb.com",
};

var URL;
if (NODE_ENV === "production" && hostname === "react.customdev.solutions")
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname === "skydivebnb.com")
  URL = servers.live;
else URL = servers.local;

// "proxy": "https://react.customdev.solutions:3016",

export const SOCKET_URL = `${URL}`;

export const UPLOADS_URL = URL + "/Uploads/";
export const BASE_URL = URL + "/api";

export const SITE_NAME = "My Website";
export const countries = ["USA", "Canada", "UK"];

export const AUTH = {
  login: "/auth/signin",
};
export const USER_AUTH = {
  login: "/user/login",
  signup: "/user/signup",
  getAccountDetails: "/user/getAccountDetails",
};
export const COMPANY = {
  getPricing: "/company/getPricing/",
  getProfile: "/company/getProfile/",
};
export const PROFILE = {
  getMyProfile: "/user/getMyProfile",
  createProfile: "/user/createProfile",
  updateMyProfile: "/user/updateMyProfile",
};
export const JOB = {
  getCompanyJobs: "/company/career/getAllJobs/",
  getJob: "/company/career/getJob/",
};
export const JUMP = {
  getMyJumps: "/jump/getMyJumps",
  getJump: "/jump/getJump/",
  getLatestJump: "/jump/getLatestJump/",
  bookJump: "/jump/bookJump",
};
export const BOOKING = {
  mybookings: "/booking/getMyBookings",
  getbooking: "/booking/getBooking/",
  bookStay: "/booking/bookStay",
};
export const EVENT = {
  getupcomingevents: "/company/event/getUpcomingEvents",
  getevent: "/company/event/getEvent/",
};
export const INSTRUCTOR = {
  getAllInstructors: "/company/getAllInstructors/",
  getInstructor: "/company/getInstructor/",
};
export const GIGS = {
  getAllGigs: "/company/getAllGigs",
  getGig: "/company/getGig/",
  getCompanyGigs: "/company/getCompanyGigs/",
};
export const AMENITY = {
  getAmenities: "/amenities/getAmenities",
};
export const SIGNWAIVER = {
  approvePolicy: "/signwaiver/policyApproval",
  getMySignwaivers: "/signwaiver/getMySignwaivers",
};
export const PRODUCT = {
  getVendorProducts: "/product/getAllVendorProducts",
  getProduct: "/product/getProduct/",
  getAdminProducts: "/product/getAllAdminProducts",
};
export const REVIEWS = {
  getGigReviews: "/review/getGigReviews/",
  getProductReviews: "/review/getProductReviews/",
  addReview: "/review/addReview",
  getRoomReviews: "/review/getRoomReviews/",
};
export const RESET = {
  sendCode: "/reset/sendVerificationCode",
  verifyCode: "/reset/verifyRecoverCode",
  resetPassword: "/reset/resetPassword",
  changePassword: "/reset/changePassword",
};
export const COUPON = {
  getCoupons: "/coupon/getCoupons",
  getCoupon: "/coupon/getCoupon/",
};
export const GIFT = {
  addGift: "/gift/addGift",
  utilizeGift: "/gift/utilizeGift",
  changeGiftStatus: "/gift/changeGiftStatus/",
};
export const ORDER = {
  getMyOrders: "/order/getMyOrders",
  getOrder: "/order/getOrder/",
  addOrder: "/order/addOrder",
};
export const STAY = {
  getAllRooms: "/stay/getAllRooms",
  getRoom: "/stay/getRoom/",
};
export const PAYMENT = {
  paymentConfig: "/payment/config",
  paymentIntent: "/payment/create-payment-intent",
  stripeCharge: "/payment/create-stripe-charge",
};
export const FEEDBACK = {
  addFeedback: "/feedback/addFeedback",
};
export const NOTIFICATIONS = {
  getMyNotifications: "/notification/getAllMyNotifications",
  toggleNotification: "/notification/toggleNotification/",
};
export const RIDE = {
  bookRide: "/ride/bookRide",
  getUserOngoingRide : "/ride/getUserOngoingRide"
};
export const REVIEW = {
  addReview:'/review/addReview'
}
// export const GOOGLE_MAPS = "AIzaSyBXqV9bSEkfm5Wh7OQMj37V-n3F4AiyE40"
// export const GOOGLE_MAPS = 'AIzaSyBXqV9bSEkfm5Wh7OQMj37V-n3F4AiyE40'
export const GOOGLE_MAPS = "AIzaSyBECY2aNK5YkXshm_ZEqtZY0M_hcJT65Iw";

export const USERS = {
  get: "/auth/signin",
};
