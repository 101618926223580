import { Col, Row, Card, Rate, Button } from "antd";
import { useLocation, useNavigate } from "react-router";
import { MdBalcony, MdOutlineKingBed, MdFreeBreakfast } from "react-icons/md";
import { FaShower, FaCalendar } from "react-icons/fa";
import { FaArrowLeftLong, FaLocationDot } from "react-icons/fa6";
import { bookingDate } from "../../../components/Data/data";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addData } from "../../../redux/slice/afterPaymentSlice";
import { extractDate } from "../../../config/constants";

const AsNavFor = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const [nights, setNights] = useState(0);
  const [stayCharges, setStayCharges] = useState(0);
  const [vat, setVat] = useState(0);
  const [cleaning, setCleaning] = useState(0);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const { to, from, roomDetails, extraCharges, locationDetails , availableDates } =
    location?.state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!to || !from || !roomDetails || !locationDetails || !availableDates) {
      navigate("/findNextStay");
    }
    calculate();
  }, []);
  
  const calculate = () => {
    const formattedAvailableDates = availableDates.map(date =>
      new Date(date).toISOString().split('T')[0]
    );
    const availableStartDate = new Date(formattedAvailableDates[0]);
    const availableEndDate = new Date(
      formattedAvailableDates[formattedAvailableDates.length - 1]
    );
    const start = new Date(from);
    const end = new Date(to);
    const startDate = start < availableStartDate ? availableStartDate : start;
    const endDate = end > availableEndDate ? availableEndDate : end;
    const difference = endDate.getTime() - startDate.getTime();
    let totalNights = Math.floor(difference / (1000 * 3600 * 24)) + 1;
    const total = roomDetails?.pricing * totalNights;
    setStayCharges(total);
    setNights(totalNights);
    setVat((total * 5) / 100);
    if (extraCharges) {
      setCleaning(20 * totalNights);
    }
  };

  const handleNavigate = () => {
    const formattedAvailableDates = availableDates.map(date =>
      new Date(date).toISOString().split('T')[0]
    );
    const availableStartDate = new Date(formattedAvailableDates[0]);
    const availableEndDate = new Date(
      formattedAvailableDates[formattedAvailableDates.length - 1]
    );
    const start = new Date(from);
    const end = new Date(to);
    const startDate = start < availableStartDate ? availableStartDate : start;
    const endDate = end > availableEndDate ? availableEndDate : end;
    let bookingData = {
      // checkIn : from,
      // checkOut : to,
      checkIn : startDate,
      checkOut : endDate,
      charges : stayCharges,
      totalStay : nights,
      cleaningCharges : cleaning,
      totalPayable : stayCharges + cleaning + vat,
      room : roomDetails?._id,
      user : profileDetails?._id,
      roomTitle : roomDetails?.title,
      vat : vat,
    }
    dispatch(addData({type : 'BOOKING', data : bookingData}))
    navigate("/payment", {
      state: { total: stayCharges + cleaning + vat, type: "BOOKING" },
    });
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">checkout</h3>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={16}>
              <Card className="details-card">
                <Row align="middle" gutter={16}>
                  <Col lg={10}>
                    <div className="search-img">
                      <div>
                        <span>{bookingDate[0].pic}</span>
                      </div>
                      <div className="rating-box">
                        <Rate
                          allowHalf
                          // defaultValue={bookingDate[0].rating}
                          defaultValue={4}
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={14}>
                    <div className="room-details">
                      <div>
                        <h4 className="text-26">{roomDetails?.title}</h4>
                        <p className="icon-span">
                          <FaLocationDot />{" "}
                          {locationDetails?.address +
                            ", " +
                            locationDetails?.street +
                            " " +
                            locationDetails.state}
                        </p>
                        <p className="icon-span">
                          <FaCalendar /> {extractDate(from)}
                        </p>
                        <p className="icon-span">
                          <FaCalendar /> {extractDate(to)}
                        </p>
                      </div>
                    </div>
                    <div className="search-img-box-right additional-details">
                      <h5 className="heading-18">Additional Details</h5>
                      <p>
                        <MdBalcony />
                        {roomDetails?.description}
                      </p>
                      <p>
                        <FaShower />
                        {roomDetails?.bathrooms + " Bathrooms"}
                      </p>
                      <p>
                        <MdOutlineKingBed />
                        {roomDetails?.beds + " Beds"}
                      </p>
                    </div>
                    <div>
                      <Button
                        type=""
                        className="web-btn"
                        onClick={handleNavigate}
                      >
                        Proceed To Pay
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={8}>
              <div
                class="details-card price-summary-card"
                style={{ marginBottom: "0" }}
              >
                <h2 class="price-summary-heading">Price Summary</h2>
                <div class="price-summary-detail">
                  <p>Total Nights</p>
                  <p>{nights + " Nights"}</p>
                </div>
                <div class="price-summary-detail">
                  <p>{`Stay Charges ($${roomDetails?.pricing}x${nights})`}</p>
                  <p>${stayCharges}</p>
                </div>
                <div class="price-summary-detail">
                  <p>{`Cleaning Charges ($${
                    extraCharges ? `20x${nights}` : `0x${nights}`
                  })`}</p>
                  <p>${cleaning}</p>
                </div>
                <div class="price-summary-detail">
                  <p>5% VAT</p>
                  <p>${vat}</p>
                </div>
              </div>
              <div class="total-paid">
                <p>Total Paid</p>
                <p>${stayCharges + cleaning + vat}</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AsNavFor;
