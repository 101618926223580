import React, { useEffect, useState } from "react";
import { Get } from "../../config/api/get";
import { SIGNWAIVER } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { PDFDocument } from "pdf-lib";

const Signs = () => {
  const token = useSelector((state) => state.user.userToken);
  const [signs, setSigns] = useState(null);

  const getMySigns = () => {
    Get(SIGNWAIVER.getMySignwaivers, token)
      .then((response) => {
        if (response?.status) {
          setSigns(response?.data);
          console.log(response);
        }
      })
      .catch((err) => {
        console.log("Error fetching Sign waivers ", err);
      });
  };

  useEffect(() => {
    getMySigns();
  }, []);
  const createPDF = async (content) => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { height } = page.getSize();
    const fontSize = 12;
    const lineHeight = 1.2 * fontSize;

    const drawTextWithWrap = (text, options) => {
      const {
        x,
        y,
        maxWidth,
        maxHeight,
        fontSize,
        lineHeight,
        align = "left",
      } = options;
      const lines = page.drawText(text, {
        x,
        y,
        size: fontSize,
        maxWidth,
        lineHeight,
      });
      if (!lines) return;
      let yOffset = y;
      lines.forEach((line) => {
        yOffset -= lineHeight;
        if (yOffset < maxHeight) return;
      });
    };

    const drawHeading = (text, options) => {
      const { x, y, fontSize, align } = options;
      page.drawText(text, { x, y, size: fontSize, align });
    };

    const startY = height - 40;
    const marginBottom = 20;

    drawHeading("Sign Waiver", {
      x: 50,
      y: startY,
      fontSize: 18,
      align: "center",
    });

    drawHeading("Dropzone Company Details", {
      x: 50,
      y: startY - marginBottom,
      fontSize: 14,
    });
    drawTextWithWrap(
      `Company Name: ${content.dropzoneCompany.firstName} ${content.dropzoneCompany.lastName}`,
      {
        x: 50,
        y: startY - marginBottom * 3,
        maxWidth: 400,
        maxHeight: startY - marginBottom * 4,
        fontSize,
        lineHeight,
      }
    );
    drawTextWithWrap(`Email: ${content.dropzoneCompany.email}`, {
      x: 50,
      y: startY - marginBottom * 5,
      maxWidth: 400,
      maxHeight: startY - marginBottom * 6,
      fontSize,
      lineHeight,
    });

    drawHeading("User Details", {
      x: 50,
      y: startY - marginBottom * 7,
      fontSize: 14,
    });
    drawTextWithWrap(`Name: ${content.firstName} ${content.lastName}`, {
      x: 50,
      y: startY - marginBottom * 8,
      maxWidth: 400,
      maxHeight: startY - marginBottom * 9,
      fontSize,
      lineHeight,
    });
    drawTextWithWrap(`Email: ${content.email}`, {
      x: 50,
      y: startY - marginBottom * 10,
      maxWidth: 400,
      maxHeight: startY - marginBottom * 11,
      fontSize,
      lineHeight,
    });
    drawTextWithWrap(`Phone: ${content.phone}`, {
      x: 50,
      y: startY - marginBottom * 12,
      maxWidth: 400,
      maxHeight: startY - marginBottom * 13,
      fontSize,
      lineHeight,
    });
    drawTextWithWrap(`License Number: ${content.licenseNo}`, {
      x: 50,
      y: startY - marginBottom * 14,
      maxWidth: 400,
      maxHeight: startY - marginBottom * 15,
      fontSize,
      lineHeight,
    });

    drawHeading("Agreed Policies", {
      x: 50,
      y: startY - marginBottom * 16,
      fontSize: 14,
    });
    drawTextWithWrap(
      "1- It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since.",
      {
        x: 50,
        y: startY - marginBottom * 17,
        maxWidth: 400,
        maxHeight: startY - marginBottom * 18,
        fontSize,
        lineHeight,
      }
    );
    drawTextWithWrap(
      "2- Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
      {
        x: 50,
        y: startY - (marginBottom + 1) * 20, // Adjusted y position
        maxWidth: 400,
        maxHeight: startY - (marginBottom + 1) * 21, // Adjusted y position
        fontSize,
        lineHeight,
      }
    );
    drawTextWithWrap(
      "3- Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since",
      {
        x: 50,
        y: startY - (marginBottom + 3) * 23, // Adjusted y position
        maxWidth: 400,
        maxHeight: startY - (marginBottom + 3) * 24, // Adjusted y position
        fontSize,
        lineHeight,
      }
    );
    drawTextWithWrap(
      "4- Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage.",
      {
        x: 50,
        y: startY - (marginBottom + 7) * 26, // Adjusted y position
        maxWidth: 400,
        maxHeight: startY - (marginBottom + 7) * 27, // Adjusted y position
        fontSize,
        lineHeight,
      }
    );

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };

  const downloadPDF = async (sign, fileName) => {
    const pdfBytes = await createPDF(sign);
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  console.log(signs);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Sign Waivers</h3>
                      </div>
                      <div className="bg-parent">
                        <Row
                          gutter={[16, 16]}
                          align={"middle"}
                          justify={"space-between"}
                        >
                          <Col md={24} lg={24} xl={8}>
                            {signs &&
                              signs.map((sign, index) => (
                                <div key={index}>
                                  <button
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      downloadPDF(sign, `Sign_waiver${index}`)
                                    }
                                  >
                                    Sign Waiver ({" "}
                                    {sign?.dropzoneCompany?.firstName +
                                      sign?.dropzoneCompany?.lastName}{" "}
                                    )
                                  </button>
                                </div>
                              ))}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Signs;
