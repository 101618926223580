import React from "react";
import { Row, Col, Typography, Button, Image } from "antd";
import appstore from "../../../assets/app-store.png";
import googleplay from "../../../assets/google-play.png";
import divingimg from "../../../assets/diving-img.webp";
// import 'antd/dist/antd.css';

const { Title } = Typography;

const Topbanner = () => {
  return (
    <div className="banner-section">
      <div className="container-fluid px-0">
        <Row gutter={0}>
          <div className="banner-text-box">
            <h3 className="experience-text">Welcome to</h3>
            <h1>skyDive bnb</h1>
            {/* <h3 className="jumpcommunity">Jump Community</h3> */}
            <div className="banner-apps-img pt-2" style={{ textAlign: 'right', paddingTop: "10px" }}>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={appstore}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={googleplay}
                  className=""
                />
              </a>
            </div>
          </div>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={divingimg}
            className="diving-img"
          />
        </Row>
      </div>
    </div>
  );
};

export default Topbanner;
