import { Modal, Form, Input, Button, Rate, Typography, message } from "antd";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import { REVIEW } from "../../config/constants/api";
import "./style.css";
import swal from "sweetalert";

const { TextArea } = Input;
const { Title, Text } = Typography;
const ReviewModal = ({ open, handleOk, handleCancel, firstName, lastName, driver }) => {
  const [form] = Form.useForm();

  // Retrieve rideData and userToken from Redux store
  //   const rideData = useSelector((state) => state?.data?.rideData);
  //   console.log(rideData, "rideData");
  const token = useSelector((state) => state?.user?.userToken);
  //   console.log(token, "token13");

  const onFinish = async (values) => {
    try {
      const data = {
        personName : firstName + ' ' + lastName,
        description: values.review,
        rating: values.rating,
        driver: driver,
        type: 'DRIVER'
      };
      const response = await Post(REVIEW.addReview, data, token);
      if (response?.status) {
        swal("System Alert", response?.message, "success");
        // message.success("Review added successfully!");
        handleOk();
      } else {
        message.error(response?.message || "Failed to add review");
      }
    } catch (error) {
      console.error("Error adding review:", error);
      message.error("An unexpected error occurred.");
    }
  };

  return (
    <Modal
      centered
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title="Review and Ratings"
      footer={null}
      className="StyledModal"
      style={{
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
      }}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        {/* <Typography>{`How was your experience with ${firstName} ${lastName}`}</Typography> */}
        <Title level={4}>
          How was Your Experience with {firstName} {lastName}?
        </Title>
        <Form.Item
          name="rating"
          rules={[{ required: true, message: "Please rate your experience" }]}
        >
          <Rate />
        </Form.Item>
        <Form.Item
          label="Write Review"
          name="review"
          rules={[
            {
              required: true,
              message: "Please enter your Review!",
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Write your review.."
            maxLength={100}
            className="web-textarea"
          />
        </Form.Item>

        <Form.Item>
          <Button type="" htmlType="submit" className="btn web-btn px-5">
            Submit Now
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            style={{ textDecoration: "underline" }}
            onClick={handleCancel}
          >
            Skip
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReviewModal;
