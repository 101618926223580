import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Card,
  Drawer,
  Rate,
  Select,
  Checkbox,
  Spin,
  Pagination,
} from "antd";
import { useNavigate } from "react-router";
import { MdMenu } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Get } from "../../../config/api/get";
import { useSelector } from "react-redux";
import { GIGS, UPLOADS_URL, AMENITY } from "../../../config/constants/api";
import { Country, State, City } from "country-state-city";

const DropzoneFiltercards = () => {
  const token = useSelector((state) => state.user.userToken);
  const [dropzones, setDropzones] = useState(null);
  const [amenities, setAmenities] = useState([]);
  const [amenityIds, setAmenityIds] = useState([]);
  const [dropzoneName, setDropzoneName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [countryIso, setCountryIso] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateIso, setStateIso] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = dropzones
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStateName("");
    setStates(State.getStatesOfCountry(countryIso));
  }, [countryName]);
  useEffect(() => {
    setCityName("");
    setCitites(City.getCitiesOfState(countryIso, stateIso));
  }, [stateName]);

  const initialFilteredData = {
    dropzoneName: dropzoneName,
    city: cityName,
    country: countryName,
    state: stateName,
    amenityIds: amenityIds,
  };
  const checkFilters = (obj) => {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        continue;
      }
      if (!obj[key]) {
        continue;
      }
      return false;
    }
    return true;
  };
  const handleSearch = () => {
    setLoading(true);
    const options = {
      country: countryName,
      city: cityName,
      name: dropzoneName,
      state: stateName,
      amenities: amenityIds?.length > 0 ? amenityIds : null,
    };
    Get(GIGS.getAllGigs, token, options)
      .then((response) => {
        setDropzones(response?.data?.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error Fetching Data", err);
        setLoading(false);
      });
  };
  const getDropzonesData = (pageNumber, pageSize) => {
    setLoading(true);
    Get(GIGS.getAllGigs, token , {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
    })
      .then((response) => {
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
          totalPages: response?.data?.totalPages,
        });
        setDropzones(response?.data?.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error Fetching Dropzones Data", err);
        setLoading(false);
      });
  };
  const getJumpAmenities = () => {
    Get(AMENITY.getAmenities, token, { type: "JUMP" })
      .then((response) => {
        setAmenities(response?.data?.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClear = () => {
    dropzoneName && setDropzoneName(null);
    cityName && setCityName("");
    countryName && setCountryName("");
    stateName && setStateName("");
    amenityIds.length > 0 && setAmenityIds([]);
    getDropzonesData();
  };
  useEffect(() => {
    getDropzonesData();
  }, []);
  useEffect(() => {
    getJumpAmenities();
  }, []);
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getDropzonesData(pageNumber, paginationConfig.limit);
  };
  const toCamelCase = (text) => {
    return text
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
      .replace(/^[A-Za-z]/, (match) => match.toUpperCase());
  };
  const navigate = useNavigate();
  const handleChange = (fieldName, value, e) => {
    switch (fieldName) {
      case "City":
        setCityName(value);
        break;
      case "Country":
        const { name, isoCode } = JSON.parse(value);
        setCountryName(name);
        setCountryIso(isoCode);
        break;
      case "State":
        setStateName(value);
        setStateIso(e?.key);
        break;
      default:
        return;
    }
  };
  const handleCheckboxChange = (e, amenityId) => {
    const { checked } = e.target;
    if (checked) {
      setAmenityIds((prevIds) => [...prevIds, amenityId]);
    } else {
      setAmenityIds((prevIds) => prevIds.filter((id) => id !== amenityId));
    }
  };
  const { Meta } = Card;

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Drop Zone</h3>
              </div>
            </Col>
          </Row>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={0} md={10} lg={7} xl={6}>
                <div className="left-div">
                  <div className="filter-heading">
                    <p>Filters</p>
                  </div>
                  <div className="inner-filter-box filter-canvas">
                    <Form layout="vertical">
                      <Form.Item label="Dropzone Name">
                        <Input
                          size="large"
                          placeholder="Dropzone Name"
                          className="web-input"
                          onChange={(e) => {
                            setDropzoneName(e.target.value);
                          }}
                          value={dropzoneName}
                        />
                      </Form.Item>

                      {/* <Form.Item label="Country">
                        <Space wrap className="filter-select">
                          <Select
                            defaultValue="Select"
                            onChange={(val, e) => {
                              handleChange("Country", val, e);
                            }}
                            value={countryName}
                            style={{ width: "100%" }}
                          >
                            {Array.isArray(countries) &&
                              countries.map((countryObj) => (
                                <Select.Option
                                  key={countryObj?.isoCode}
                                  value={countryObj?.name}
                                >
                                  {countryObj?.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Space>
                      </Form.Item> */}
                      <Form.Item label="Country" name="Country">
                        <Space wrap className="filter-select">
                          <Select
                            size="large"
                            defaultValue="Select"
                            showSearch
                            placeholder="Country"
                            optionFilterProp="children"
                            onChange={(val, e) => {
                              handleChange("Country", val, e);
                            }}
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countries?.map((country) => ({
                              value: JSON.stringify({
                                name: country.name,
                                isoCode: country.isoCode,
                              }), // Stringify the wrapper object
                              label: country?.name,
                            }))}
                          />
                        </Space>
                      </Form.Item>
                      <Form.Item label="State">
                        <Space wrap className="filter-select">
                          <Select
                            defaultValue="Select"
                            onChange={(val, e) => {
                              handleChange("State", val, e);
                            }}
                            value={stateName}
                            style={{ width: "100%" }}
                          >
                            {states.map((stateObj) => (
                              <Select.Option
                                key={stateObj?.isoCode}
                                value={stateObj?.name}
                              >
                                {stateObj?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                      </Form.Item>
                      <Form.Item label="City">
                        <Space wrap className="filter-select">
                          <Select
                            defaultValue="Select"
                            onChange={(val, e) => {
                              handleChange("City", val, e);
                            }}
                            value={cityName}
                            style={{ width: "100%" }}
                          >
                            {cities.map((cityObj) => (
                              <Select.Option
                                key={cityObj?.isoCode}
                                value={cityObj?.name}
                              >
                                {cityObj?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                      </Form.Item>

                      {/* <Form.Item label="City">
                        <Space wrap className="filter-select">
                          <Select
                            defaultValue="lucy"
                            onChange={(val) => {
                              handleChange("City", val);
                            }}
                            value={city}
                            style={{ width: "100%" }}
                            options={[
                              { value: "jack", label: "Jack" },
                              { value: "lucy", label: "Lucy" },
                              { value: "Yiminghe", label: "yiminghe" },
                              { value: "chicago", label: "chicago" },
                              { value: null, label: "Select" },
                            ]}
                          />
                        </Space>
                      </Form.Item> */}
                    </Form>
                    <Form
                      layout="vertical"
                      name="basic"
                      initialValues={{
                        remember: true,
                      }}
                    >
                      <Form.Item>
                        <h6 className="sort-by">Amenities</h6>
                      </Form.Item>
                      {amenities.length > 0 &&
                        amenities.map((amenity, index) => {
                          return (
                            <Form.Item
                              key={index}
                              name="remember"
                              style={{ marginBottom: 0 }}
                            >
                              <Checkbox
                                value={amenity._id}
                                checked={amenityIds.includes(amenity._id)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, amenity._id)
                                }
                              >
                                {amenity.title}
                              </Checkbox>
                            </Form.Item>
                          );
                        })}
                    </Form>
                    <div className="apply-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className="web-btn"
                        disabled={
                          checkFilters(initialFilteredData) ||
                          !(dropzones.length > 0)
                        }
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    </div>

                    <div className="clear-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className=""
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#000" }}
                    onClick={showDrawer}
                  />
                  <Drawer
                    // title="Basic Drawer"
                    placement="right"
                    onClose={onClose}
                    open={open}
                  >
                    <div className="left-div">
                      <div className="filter-heading">
                        <p>Filters</p>
                      </div>
                      <div className="inner-filter-box filter-canvas">
                        <Form layout="vertical">
                          <Form.Item label="Dropzone Name">
                            <Input
                              size="large"
                              placeholder="Dropzone Name"
                              className="web-input"
                              onChange={(e) => {
                                setDropzoneName(e.target.value);
                              }}
                              value={dropzoneName}
                            />
                          </Form.Item>

                          <Form.Item label="Country" name="Country">
                            <Space wrap className="filter-select">
                              <Select
                                size="large"
                                defaultValue="Select"
                                showSearch
                                placeholder="Country"
                                optionFilterProp="children"
                                onChange={(val, e) => {
                                  handleChange("Country", val, e);
                                }}
                                filterOption={(input, option) =>
                                  option.value
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={countries?.map((country) => ({
                                  value: JSON.stringify({
                                    name: country.name,
                                    isoCode: country.isoCode,
                                  }), // Stringify the wrapper object
                                  label: country?.name,
                                }))}
                              />
                            </Space>
                          </Form.Item>
                          <Form.Item label="State">
                            <Space wrap className="filter-select">
                              <Select
                                defaultValue="Select"
                                onChange={(val, e) => {
                                  handleChange("State", val, e);
                                }}
                                value={stateName}
                                style={{ width: "100%" }}
                              >
                                {states.map((stateObj) => (
                                  <Select.Option
                                    key={stateObj?.isoCode}
                                    value={stateObj?.name}
                                  >
                                    {stateObj?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Space>
                          </Form.Item>
                          <Form.Item label="City">
                            <Space wrap className="filter-select">
                              <Select
                                defaultValue="Select"
                                onChange={(val, e) => {
                                  handleChange("City", val, e);
                                }}
                                value={cityName}
                                style={{ width: "100%" }}
                              >
                                {cities.map((cityObj) => (
                                  <Select.Option
                                    key={cityObj?.isoCode}
                                    value={cityObj?.name}
                                  >
                                    {cityObj?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Space>
                          </Form.Item>
                        </Form>
                        <h6 className="sort-by">Amenities</h6>
                        <Form
                          layout="vertical"
                          name="basic"
                          initialValues={{
                            remember: true,
                          }}
                        >
                          {amenities.length > 0 &&
                            amenities.map((amenity, index) => {
                              return (
                                <Form.Item
                                  key={index}
                                  name="remember"
                                  checked={amenityIds.includes(amenity._id)}
                                  style={{ marginBottom: 0 }}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, amenity._id)
                                  }
                                >
                                  <Checkbox>{amenity.title}</Checkbox>
                                </Form.Item>
                              );
                            })}
                        </Form>

                        <div className="apply-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className="web-btn"
                            disabled={
                              checkFilters(initialFilteredData) ||
                              !(dropzones.length > 0)
                            }
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </div>

                        <div className="clear-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className=""
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>

              {!loading ? (
                <Col xs={24} md={14} lg={17} xl={18}>
                  <Row gutter={16}>
                    {Array.isArray(dropzones) && dropzones?.length > 0 ? (
                      dropzones.map((data, index) => (
                        <Col xs={24} lg={8} key={index}>
                          <div>
                            <Space
                              direction="vertical"
                              size="middle"
                              style={{
                                width: "100%",
                                padding: "8px",
                              }}
                            >
                              <div className="search-img">
                                <Card
                                  className="card booking-card dropzone-card"
                                  cover={
                                    <span>
                                      <img
                                        src={UPLOADS_URL + data?.image}
                                        alt=""
                                        className="img-fluid"
                                        style={{ width: "100%" }}
                                      />
                                    </span>
                                  }
                                >
                                  <Meta title={data?.title} />
                                  <p className="web-p">
                                    {toCamelCase(data?.jumpType) + " Jump"}
                                  </p>
                                  <h6
                                    className="blue-link"
                                    style={{
                                      textAlign: "left",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      navigate("/dropzoneCompany", {
                                        state: {
                                          companyId: data?.dropzoneCompany,
                                        },
                                      })
                                    }
                                  >
                                    {data?.companyDetails[0]?.firstName +
                                      " " +
                                      data?.companyDetails[0]?.lastName}
                                  </h6>
                                  <h6 className="booking-card-price">
                                    ${data?.charges}
                                  </h6>
                                  <Button
                                    type="link"
                                    className="web-btn"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                    onClick={() =>
                                      navigate("/dropZone/" + data?._id)
                                    }
                                  >
                                    View Details
                                  </Button>
                                </Card>
                                {data?.averageRating && (
                                  <div className="rating-box">
                                    <Rate
                                      allowHalf
                                      defaultValue={data?.averageRating}
                                      disabled
                                    />
                                  </div>
                                )}
                              </div>
                            </Space>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "22px",
                          margin: "300px auto",
                        }}
                      >
                        No Dropzones Data to Show
                      </p>
                    )}
                  </Row>
                </Col>
              ) : (
                <Col
                  xs={24}
                  md={14}
                  lg={17}
                  xl={18}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "150px 0px",
                  }}
                >
                  <Spin size="large" />
                </Col>
              )}
            </Row>
            <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <p>{message}</p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    className="styledPagination"
                    onChange={(e) => handlePageChange(e)}
                    current={parseInt(paginationConfig?.pageNumber)}
                    pageSize={paginationConfig?.limit}
                    total={paginationConfig?.totalDocs}
                    itemRender={itemRender}
                  />
                </Col>
              </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
