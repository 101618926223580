import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import { useLocation, useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Get } from "../../config/api/get";
import { EVENT } from "../../config/constants/api";
const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric", timeZone: "UTC" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};
const UpcomingEvents = () => {
  const location = useLocation();
  const companyId = location?.state?.companyId;
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const getEvents = () => {
    setLoading(true);
    let params = {};
    if (companyId) {
      params = {
        companyId,
      };
    }
    Get(EVENT.getupcomingevents, token, params)
      .then((response) => {
        setUpcomingEvents(response?.data?.docs);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Fetching events", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getEvents();
  }, []);


  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Upcoming Events</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {!loading ? (
              <Col lg={22}>
                {upcomingEvents && upcomingEvents.length > 0 ? (
                  upcomingEvents.map((data, index) => (
                    <Card className="details-card" key={index}>
                      <Row align="middle" gutter={24}>
                        <Col lg={6}>
                          <div
                            className=" event-date-box"
                            style={{ textAlign: "center", marginRight: "20px" }}
                          >
                            <h6>
                              {dateConverter(data.date)
                                .split(" ")
                                .map((part, index) => (
                                  <React.Fragment key={index}>
                                    {index > 0 && <br />} {part}
                                  </React.Fragment>
                                ))}
                            </h6>
                          </div>
                        </Col>
                        <Col lg={15}>
                          <h5 className="text-22-urb">{data.title}</h5>
                          <p className="web-p">{data.description}</p>
                          <Button
                            type="link"
                            className="web-btn"
                            onClick={() =>
                              companyId
                                ? navigate(
                                    "/dropzoneCompany/upcomingEvents/" +
                                      data._id
                                  )
                                : navigate("/upcomingEvents/" + data._id)
                            }
                          >
                            View Details
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  ))
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      margin: "70px 0px",
                    }}
                  >
                    No Events to Show
                  </p>
                )}
              </Col>
            ) : (
              <Col
                lg={22}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "150px 0px",
                }}
              >
                <Spin />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
