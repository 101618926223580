import { useState } from "react";
import { Col, Row, Skeleton, Table, Image, Button } from "antd";
import OrderSummary from "../../components/orderSummary";
import QuantitySelector from "../../components/quantity-selector";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UPLOADS_URL } from "../../config/constants/api";
import { updateCartItemQuantity, removeFromCart } from "../../redux/slice/cartSlice";

function MyCart() {
  const cart = useSelector((state) => state.cart.cartItems);
  console.log(cart,'myCart')
  const subTotal = useSelector((state) => state.cart.subTotal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSelectedQuantityChange = (qty, value, index) => {
    const { name } = value;
    dispatch(updateCartItemQuantity({ name, quantity: qty }));
  };
  const removeItemFromCart = (product) => {
    dispatch(removeFromCart({name :product.name}))
  };
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (item) => (
        <Image
          preview={false}
          src={UPLOADS_URL + item}
          width={"48px"}
          height={"48px"}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "PRODUCT",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      render: (item, value, index) => (
        <QuantitySelector
          value={item}
          onChange={(qty) => handleSelectedQuantityChange(qty, value, index)}
        />
      ),
    },

    {
      title: "UNIT PRICE",
      dataIndex: "price",
      key: "price",
      render: (item) => <span>${item}</span>,
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (item, value) => <span>${value.quantity * value.price}</span>,
    },
    {
      title: "Remove",

      render: (item, value) => (
        <Button
          onClick={() => {
            removeItemFromCart(value);
          }}
        >
          Remove Item
        </Button>
      ),
      // onClick={() => handleRemoveItem(item.key)}
    },
  ];
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={22} xl={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h3 className="main-heading">My Cart</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={22}>
              <Row gutter={30}>
                <Col xs={24} md={15} lg={17}>
                  <Row style={{ padding: 20, overflow: "auto" }}>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Skeleton active />
                        <br />
                      </div>
                    ) : (
                      <Table
                        className="styledTable2"
                        dataSource={cart}
                        columns={columns}
                        pagination={false}
                      />
                    )}
                  </Row>
                </Col>
                <Col xs={24} md={9} lg={7}>
                  <OrderSummary subTotal={subTotal} />
                </Col>
                <Col lg={24}>
                  <Button
                    className="mainbtn web-btn"
                    onClick={() =>
                      navigate("/checkout", { state: { subTotal: subTotal } })
                    }
                    disabled={!cart.length > 0}
                    style={{ margin: "10px 20px" }}
                  >
                    Proceed To Checkout
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default MyCart;
