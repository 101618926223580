import React from "react";
import { Layout } from "antd";
import DropzoneFiltercards from "../../views/dropZone/dropzoneFiltercards";

function DropZone() {

  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
    <DropzoneFiltercards/>

    </Layout>
  );
}

export default DropZone;
