import { useState } from "react";
import { Col, Row, Drawer, Image } from "antd";
import myProfileicon from "../../assets/myprofile.png";
import jumplogicon from "../../assets/jumplog.png";
import gifticon from "../../assets/gift.png";
import clockicon from "../../assets/clock.png";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { LiaFileContractSolid } from "react-icons/lia";

import { MdMenu } from "react-icons/md";
const SideMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  return (
    <>
      <Row>
        <Col xs={0} md={0} lg={0} xl={24}>
          <div className="side-menu">
            <div
              className={`vector-one-parent ${
                location.pathname === "/profile" ||
                location.pathname === "/Profile" ||
                location.pathname === "/editProfile"
                  ? "active"
                  : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={myProfileicon}
                className="vector-one-icon"
              />
              <div
                className="my-profile"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/profile")}
              >
                My Profile
              </div>
            </div>
            <div
              className={`gift-cards-text-parent ${
                location.pathname.includes("/stayReservationLogs")
                  ? "active"
                  : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={jumplogicon}
                className="vector-one-icon"
              />
              <div
                className="jump-reservation-logs"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/stayReservationLogs")}
              >
                My Stay Bookings
              </div>
            </div>
            <div
              className={`gift-cards-text-parent ${
                location.pathname.includes("/jumpReservationLogs")
                  ? "active"
                  : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={jumplogicon}
                className="vector-one-icon"
              />
              <div
                className="jump-reservation-logs"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/jumpReservationLogs")}
              >
                Jump Reservation Logs
              </div>
            </div>
            <div
              className={`location-text-parent ${
                location.pathname.includes("/giftCards") ? "active" : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={gifticon}
                className="vector-one-icon"
              />
              <div
                className="gift-cards"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/giftCards")}
              >
                Gift Cards
              </div>
            </div>
            <div
              className={`edit-profile-change-password-g-parent ${
                location.pathname.includes("/myOrders") ? "active" : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={clockicon}
                className="vector-one-icon"
              />
              <div
                className="my-orders"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/myOrders")}
              >
                My Orders
              </div>
            
            </div>
            <div
              className={`edit-profile-change-password-g-parent ${
                location.pathname.includes("/mySigns") ? "active" : ""
              }`}
            >
             <LiaFileContractSolid style={{fontSize:'26px'}} />
              <div
                className="my-orders"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/mySigns")}
              >
                My Signs
              </div>
            
            </div>
          </div>
        </Col>
        <Col xs={24} md={24} lg={24} xl={0}>
          <>
            <MdMenu
              style={{ fontSize: 26, color: "#000" }}
              onClick={showDrawer}
            />
            <Drawer placement="right" onClose={onClose} open={open}>
              <div className="side-menu">
                <div className="bg2" />
                <div className="vector-one-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={myProfileicon}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-profile"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/profile")}
                  >
                    My Profile
                  </div>
                </div>
                <div className="reservation-logs-text">
                  <div
                    className={`gift-cards-text-parent ${
                      location.pathname.includes("/stayReservationLogs")
                        ? "active"
                        : ""
                    }`}
                  >
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={jumplogicon}
                      className="vector-one-icon"
                    />
                    <div
                      className="jump-reservation-logs"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/stayReservationLogs")}
                    >
                      My Stay Bookings
                    </div>
                  </div>
                  <div className="gift-cards-text-parent">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={jumplogicon}
                      className="vector-one-icon"
                    />
                    <div
                      className="jump-reservation-logs"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/jumpReservationLogos")}
                    >
                      Jump Reservation Logs
                    </div>
                  </div>
                  <div className="location-text-parent">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={gifticon}
                      className="vector-one-icon"
                    />
                    <div
                      className="gift-cards"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/giftCards")}
                    >
                      Gift Cards
                    </div>
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={clockicon}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/myOrders")}
                  >
                    My Orders
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                <LiaFileContractSolid style={{fontSize:'24px'}} />

                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/mySigns")}
                  >
                    My Sign Waivers
                  </div>
                </div>
              
              </div>
            </Drawer>
          </>
        </Col>
      </Row>
    </>
  );
};

export default SideMenu;
