import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Button,
  Popover,
  Skeleton,
  Table,
  Select,
  Pagination,
  DatePicker,
  Tabs,
  Spin,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import { AiOutlineEye } from "react-icons/ai";
import { FaSearch, FaFilter } from "react-icons/fa";
import { JUMP } from "../../../config/constants/api";
import { Get } from "../../../config/api/get";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import { extractDate } from "../../../config/constants";

const JumpReservationInfo = () => {
  const token = useSelector((state) => state.user.userToken);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loads, setLoads] = useState(null);
  const [filter, setFilter] = useState({
    status: null,
    type: null,
    keyword: "",
    from: null,
    to: null,
  });

  const data = {
    status: filter.status,
    keyword: filter.keyword,
    from: filter.from,
    to: filter.to,
  };
  const handleApply = () => {
    let from;
    let to;
    let status = data.status;
    if (data.from) {
      from = moment(filter?.from?.$d).format("YYYY-MM-DD");
    }
    if (data.to) {
      to = moment(filter?.to?.$d).format("YYYY-MM-DD");
    }
    if (from || to || status) {
      getMyLoads(
        paginationConfig.pageNumber,
        paginationConfig.limit,
        status,
        from,
        to
      );
    } else {
      return;
    }
  };
  const handleClear = () => {
    resetFilter();
    getMyLoads();
  };
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  const navigate = useNavigate();
  const getMyLoads = (pageNumber, pageSize, status, from, to, keyword) => {
    setLoading(true);
    Get(JUMP.getMyJumps, token, {
      status,
      from,
      to,
      keyword,
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
    })
      .then((response) => {
        if (response?.data?.docs) {
          setLoads(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error Fetching Loads", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getMyLoads();
  }, []);

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getMyLoads(pageNumber, paginationConfig.limit);
  };

  const handleSearch = useDebouncedCallback((value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
    getMyLoads(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      null,
      null,
      null,
      value
    );
  }, 1000);

  const handleStatusChange = (value) => {
    setFilter({
      ...filter,
      status: value,
    });
  };

  const handleTypeChange = (value) => {
    setFilter({
      ...filter,
      type: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      from: null,
      to: null,
    });
    // getOrders(paginationConfig.pageNumber, paginationConfig.limit, "", true);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      from: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      to: date,
    });
  };

  // const handleLimitChange = (pageSize) => {
  //   setPaginationConfig({
  //     ...paginationConfig,
  //     limit: pageSize,
  //     current: 1,
  //   });

  //   getOrders(1, pageSize);
  // };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 9 && "0") + (index + 1),
    },
    {
      title: "Booking ID",
      dataIndex: "_id",
      key: "_id",
      render: (value, item) => "#" + value.slice(-4),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Jump Name",
      dataIndex: "gigTitle",
      key: "gigTitle",
    },
    {
      title: "Jump Type",
      dataIndex: "jumpType",
      key: "jumpType",
      render: (value, item) => capitalizeFirstLetter(value) + " Jump",
    },
    {
      title: "Date",
      dataIndex: "selectedDate",
      key: "selectedDate",
      render: (item) => <span>{extractDate(item)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <>
          {item === "UPCOMING" ? (
            <span style={{ color: "#DD9F00" }}>{item}</span>
          ) : item === "ONGOING" ? (
            <span style={{ color: "#2D308B" }}>{item}</span>
          ) : item === "COMPLETED" ? (
            <span style={{ color: "#00D640" }}>{item}</span>
          ) : (
            <span>{item}</span>
          )}
        </>
      ),
    },
    {
      title: "Cost",
      dataIndex: "charges",
      key: "charges",
      render: (value, item) => "$" + value,
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (value, item, index) => (
        <AiOutlineEye
          style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
          onClick={() =>
            navigate("/jumpReservationLogs/jumpReservationInfo/" + value, {
              state: item.status,
            })
          }
        />
      ),
    },
  ];

  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p
          className="mainLabel"
          style={{ padding: "10px", fontSize: "18px", fontWeight: "bold" }}
        >
          Filter
        </p>
      </div>
      <hr style={{ marginBottom: 10 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel">Sort By Date:</p>
        <DatePicker
          className="mainInput filterInput web-input"
          value={filter.from}
          onChange={(e) => handleFrom(e)}
          style={{ width: "100%" }}
        />
        <DatePicker
          className="mainInput filterInput web-input"
          value={filter.to}
          onChange={(e) => handleTo(e)}
          style={{ width: "100%" }}
        />

        <p className="mainLabel"> Status:</p>

        <Select
          size={"large"}
          className="filterSelectBox"
          placeholder="Select Status"
          value={filter.status}
          onChange={(e) => handleStatusChange(e)}
          style={{
            width: "100%",
            marginBottom: "10px",
            textAlign: "left",
          }}
          options={[
            { value: "UPCOMING", label: "Upcoming" },
            { value: "ONGOING", label: "Ongoing" },
            { value: "COMPLETED", label: "Completed" },
          ]}
        />

        <Button
          type=""
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="web-btn"
          onClick={handleApply}
        >
          Apply
        </Button>
        <Button
          type=""
          block
          size={"large"}
          className="web-btn"
          onClick={handleClear}
        >
          Clear All
        </Button>
      </div>
    </div>
  );

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "My Loads",
      children: (
        <Row align="middle" gutter={16}>
          <Col lg={24}>
            <div className="boxDetails">
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}></Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Popover
                    content={filterContent}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <Button
                      shape="circle"
                      style={{
                        padding: "12px 12px 5px",
                        height: "auto",
                        backgroundColor: "#0A8BD7",
                      }}
                    >
                      <FaFilter
                        style={{
                          fontSize: "16px",
                          color: "white",
                        }}
                      />
                    </Button>
                  </Popover>
                  &emsp;
                  <Input
                    style={{ width: "250px" }}
                    className="mainInput dashInput"
                    placeholder="Search Here"
                    onChange={(e) => handleSearch(e.target.value)}
                    suffix={
                      <FaSearch
                        style={{
                          color: "grey",
                          fontSize: 16,
                          cursor: "pointer",
                        }}
                      />
                    }
                  />
                </Col>
              </Row>

              <Row style={{ padding: 20, overflow: "auto" }}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Skeleton active />
                    <br />
                  </div>
                ) : (
                  <Table
                    className="styledTable"
                    dataSource={loads}
                    columns={columns}
                    pagination={false}
                  />
                )}
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <p>{message}</p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    className="styledPagination"
                    onChange={(e) => handlePageChange(e)}
                    current={parseInt(paginationConfig?.pageNumber)}
                    pageSize={paginationConfig?.limit}
                    total={paginationConfig?.totalDocs}
                    itemRender={itemRender}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className="web-btn"
          style={{ minWidth: "20px", height: "40px" }}
          onClick={() => {
            navigate("/dropZone");
          }}
        >
          {" "}
          Join Loads
        </Button>
      ),
    },
  ];
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Jump Reservation Logs</h3>
                      </div>
                      <div className="">
                        <Row justify="center">
                          {!loading ? (
                            <Col xs={24} md={24} xl={24}>
                              <Card>
                                <Tabs
                                  defaultActiveKey="1"
                                  items={items}
                                  onChange={onChange}
                                />
                              </Card>
                            </Col>
                          ) : (
                            <Col
                              xs={24}
                              md={24}
                              xl={24}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "150px 0px",
                              }}
                            >
                              <Spin />
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JumpReservationInfo;
