import React, { useState } from "react";
import { Row, Col, List, Image, Button } from "antd";
import image1 from "../../assets/gloves.png";


function ConfirmOrderDetails() {
  const [orderDetails, setOrderDetails] = useState([
    {
      key: 1,
      image: image1,
      productName: "Abc Product",
      amount: 100,
    },
    {
      key: 2,
      image: image1,
      productName: "Abc Product",
      amount: 200,
    },
    {
      key: 3,
      image: image1,
      productName: "Abc Product",
      amount: 150,
    },
  ]);
  const handleRemoveItem = (key) => {
    const updatedOrderDetails = orderDetails.filter((item) => item.key !== key);
    setOrderDetails(updatedOrderDetails);
  };
  return (
    <div className="checkout">
      <h3>ConfirmOrderDetails</h3>
      <Row>
        <Col xs={24} md={24}>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={orderDetails}
            renderItem={(item) => (
              <List.Item>
                <Row
                  gutter={20}
                  justify={"space-between"}
                  style={{ width: "100%" }}
                  align={"middle"}
                >
                  <Col>
                    <Image
                      preview={false}
                      src={item.image}
                      width={"60px"}
                      height={"60px"}
                      style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col>
                    <h6>{item.productName}</h6>
                  </Col>
                  <Col>
                    <h5>$ {item.amount}</h5>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        marginTop: 30,
                        width: 100,
                      }}
                      type="danger"
                      onClick={() => handleRemoveItem(item.key)}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ConfirmOrderDetails;
