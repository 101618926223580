import { Col, Row, Card } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation } from "react-router";
const DzBriefing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const companyProfile = location.state.companyProfile;
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">DZ Briefing</h3>
              </div>
            </Col>
          </Row>
          <Row gutter={16} justify={"center"}>
            <Col lg={19}>
              <Card className="details-card">
                <Row align="middle" gutter={16}>
                  <Col lg={12}>
                    <div className="search-img">
                      {companyProfile?.briefUrl && (
                        <div>
                          {/*  */}
                          <span>
                            <iframe
                              width="400"
                              height="320"
                              src={companyProfile?.briefUrl}
                              title="parachute final_2.mp4"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </span>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    {companyProfile?.briefTitle && (
                      <h4 className="text-26">{companyProfile?.briefTitle}</h4>
                    )}
                    {companyProfile?.briefDescription && (
                      <p className="web-p">
                        {companyProfile?.briefDescription}
                      </p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DzBriefing;
