import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Form,
  Input,
  Radio,
  Button,
  Space,
  Collapse,
  Card,
  List,
  Image,
  Drawer,
  Rate,
  Select,
  Typography,
  DatePicker,
  Badge,
  Spin,
} from "antd";
import { useNavigate } from "react-router";
import { MdMenu } from "react-icons/md";
import {
  CaretRightOutlined,
  ClockCircleFilled,
  CalendarFilled,
  StopOutlined,
} from "@ant-design/icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Get } from "../../../config/api/get";
import { BOOKING, UPLOADS_URL } from "../../../config/constants/api";
import moment from "moment";

const ServicePage = () => {
  const token = useSelector((state) => state.user.userToken);
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getMyBookings = () => {
    Get(BOOKING.mybookings, token)
    .then((response) => {
      setBookingData(response.data?.docs);
      setLoading(false);
    })
      .catch((err) => {
        console.log("Error Fetching Bookings", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getMyBookings();
  }, []);
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const formatTime = (dateTimeString) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    };
    const time = new Date(dateTimeString).toLocaleTimeString("en-US", options);
    return time;
  };
  const navigate = useNavigate();
  const [categories, setCategories] = useState([
    "Completed",
    "Ongoing",
    "Upcoming",
  ]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const initialFilteredData = {
    from: from,
    to: to,
    selectedCategory: selectedCategory,
  };
  const checkFilters = (obj) => {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        continue;
      }
      if (!obj[key]) {
        continue;
      }
      return false;
    }
    return true;
  };
  const handleFromChange = (date) => {
    setFrom(moment(date?.$d).format("YYYY-MM-DD"));
  };
  const handleToChange = (date) => {
    setTo(moment(date?.$d).format("YYYY-MM-DD"));
  };
  const handleApply = () => {
    const options = {
      from: from,
      to: to,
      status: selectedCategory?.toUpperCase(),
    };
    Get(BOOKING.mybookings, token, options)
      .then((response) => {
        setBookingData(response.data?.docs);
      })
      .catch((err) => {
        console.log("Error Fetching Bookings", err);
      });
  };
  const handleClear = () => {
    from && setFrom(null);
    to && setTo(null);
    selectedCategory && setSelectedCategory(null);
    getMyBookings();
  };
  
  const { Meta } = Card;
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">My Stay Bookings</h3>
              </div>
            </Col>
          </Row>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={0} md={10} lg={7} xl={6}>
                <div className="left-div">
                  <div className="filter-heading">
                    <p>Filters</p>
                  </div>
                  <div className="inner-filter-box filter-canvas">
                    <label htmlFor="status" className="form-label">
                      Choose Status
                    </label>
                    <>
                      <Collapse
                        defaultActiveKey={["1"]}
                        expandIconPosition="end"
                        style={{ margin: "10px 0" }}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined
                            style={{ color: "#6A6A6A", fontSize: "18px" }}
                            rotate={isActive ? 90 : 180}
                          />
                        )}
                        items={[
                          {
                            key: "1",
                            label: (
                              <span style={{ fontWeight: "bold" }}>
                                {selectedCategory}
                              </span>
                            ),
                            children: (
                              <List
                                size="small"
                                dataSource={categories}
                                renderItem={(item) => (
                                  <List.Item
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSelectedCategory(item)}
                                  >
                                    <Typography.Text mark></Typography.Text>{" "}
                                    {item}
                                  </List.Item>
                                )}
                              />
                            ),
                          },
                        ]}
                      />
                    </>
                    <Form.Item>
                      <h6 className="sort-by">Sort By</h6>
                    </Form.Item>
                    <Form
                      layout="vertical"
                      name="basic"
                      initialValues={{
                        remember: true,
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <DatePicker
                        className="form-control web-input pe-2"
                        id="fromDate"
                        value={from ? moment(from) : null}
                        onChange={handleFromChange}
                        style={{ width: "100%", paddingRight: "10px" }}
                      />
                      <DatePicker
                        className="form-control web-input pe-2"
                        id="toDate"
                        value={to ? moment(to) : null}
                        onChange={handleToChange}
                        style={{ width: "100%", paddingRight: "10px" }}
                      />
                    </Form>
                    <div className="apply-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className="web-btn"
                        onClick={handleApply}
                        disabled={
                          checkFilters(initialFilteredData) ||
                          !Array.isArray(bookingData) ||
                          !bookingData.length > 0
                        }
                      >
                        Apply
                      </Button>
                    </div>

                    <div className="clear-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className=""
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#000" }}
                    onClick={showDrawer}
                  />
                  <Drawer
                    // title="Basic Drawer"
                    placement="right"
                    onClose={onClose}
                    open={open}
                  >
                    <div className="left-div">
                      <div className="filter-heading">
                        <p>Filters</p>
                      </div>
                      <div className="inner-filter-box filter-canvas">
                        <label htmlFor="status" className="form-label">
                          Choose Status
                        </label>
                        <>
                          <Collapse
                            defaultActiveKey={["1"]}
                            expandIconPosition="end"
                            style={{ margin: "10px 0" }}
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined
                                style={{ color: "#6A6A6A", fontSize: "18px" }}
                                rotate={isActive ? 90 : 180}
                              />
                            )}
                            items={[
                              {
                                key: "1",
                                label: (
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedCategory}
                                  </span>
                                ),
                                children: (
                                  <List
                                    size="small"
                                    dataSource={categories}
                                    renderItem={(item) => (
                                      <List.Item
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          setSelectedCategory(item)
                                        }
                                      >
                                        <Typography.Text mark></Typography.Text>{" "}
                                        {item}
                                      </List.Item>
                                    )}
                                  />
                                ),
                              },
                            ]}
                          />
                        </>
                        <h6 className="sort-by">Sort By</h6>
                        <Form
                          layout="vertical"
                          name="basic"
                          initialValues={{
                            remember: true,
                          }}
                        >
                          <Form.Item label="From" name="from">
                            <DatePicker
                              className="form-control web-input pe-2"
                              id="fromDate"
                              value={from}
                              onChange={handleFromChange}
                              style={{ width: "100%", paddingRight: "10px" }}
                            />
                          </Form.Item>
                          <Form.Item label="To" name="to">
                            <DatePicker
                              className="form-control web-input pe-2"
                              id="toDate"
                              value={to}
                              onChange={handleToChange}
                              style={{ width: "100%", paddingRight: "10px" }}
                            />
                          </Form.Item>
                        </Form>

                        <div className="apply-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className="web-btn"
                            onClick={handleApply}
                            disabled={
                              checkFilters(initialFilteredData) ||
                              !Array.isArray(bookingData) ||
                              !bookingData.length > 0
                            }
                          >
                            Apply
                          </Button>
                        </div>

                        <div className="clear-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className=""
                            onClick={handleClear}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>
              {!loading ? (
                <Col xs={24} md={14} lg={17} xl={18}>
                  <Row gutter={16}>
                    {Array.isArray(bookingData) && bookingData.length > 0 ? (
                      bookingData.map((data, index) => (
                        <Col lg={8} key={index}>
                          <div>
                            <Space
                              direction="vertical"
                              size="middle"
                              style={{
                                width: "100%",
                                padding: "8px",
                              }}
                            >
                              <Badge.Ribbon
                                text={data.status}
                                color={
                                  data.status === "COMPLETED"
                                    ? "#00B31D"
                                    : data.status === "UPCOMING"
                                    ? "#DD9F00"
                                    : data.status === "ONGOING"
                                    ? "#2D308B"
                                    : "red"
                                }
                                placement="start"
                              >
                                <Card
                                  className="booking-card"
                                  cover={
                                    <span>
                                      <img
                                        src={
                                          UPLOADS_URL +
                                          data?.roomDetails[0]?.image
                                        }
                                        alt=""
                                        className="img-fluid"
                                        style={{ width: "100%" }}
                                      />
                                    </span>
                                  }
                                >
                                  <Meta
                                    title={data.title}
                                    description={
                                      <>
                                        <div className="booking-card-span">
                                          <span>
                                            <ClockCircleFilled />{" "}
                                            {formatTime(data?.checkIn)}
                                          </span>
                                          <span>
                                            <CalendarFilled />{" "}
                                            {formatDate(data?.checkIn)}
                                          </span>
                                          <span>
                                            <StopOutlined />{" "}
                                            {data?.totalStay.toString().length <
                                            2
                                              ? "0" + data?.totalStay + " days"
                                              : data?.totalStay + " days"}
                                          </span>
                                        </div>
                                        <h6 className="booking-card-price">
                                          {"$" + data?.totalPayable + "/paid"}
                                        </h6>
                                        <Button
                                          type="link"
                                          className="web-btn"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                          }}
                                          onClick={() =>
                                            navigate(
                                              "/mystayBookings/" + data._id
                                            )
                                          }
                                        >
                                          View Details
                                        </Button>
                                      </>
                                    }
                                  />
                                </Card>
                              </Badge.Ribbon>
                            </Space>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <p
                        style={{
                          margin: "300px auto",
                          fontWeight: "bold",
                          fontSize: "22px",
                        }}
                      >
                        No Bookings to Show
                      </p>
                    )}
                  </Row>
                </Col>
              ) : (
                <Col
                  xs={24}
                  md={14}
                  lg={17}
                  xl={18}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "150px 0px",
                  }}
                >
                  <Spin />
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServicePage;
