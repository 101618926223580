import React from "react";

const index = () => {
  return (
    <div style={{textAlign:'center' , margin:'20vh'}} >
      <h1>Content to be provided</h1>
    </div>
  );
};

export default index;
