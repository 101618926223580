import { Layout } from "antd";
import GiftCardsInfo from "../../views/giftCards/giftCardsInfo";

function DropZone() {
  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
      <GiftCardsInfo />
    </Layout>
  );
}

export default DropZone;
