import { Col, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import AboutUsHome from "../../views/homepage/aboutUsHome";
// const { Meta } = Card;
const { Title } = Typography;
const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};
const Terms = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Terms & Conditions</h3>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <p className="web-p" style={{textAlign:"center"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo. Proin sodales pulvinar
                tempor. Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien
                nunc eget odio. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
                dolor sit amet lacus accumsan et viverra justo commodo. Proin
                sodales pulvinar tempor. Cum sociis natoque.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Aenean euismod bibendum
                laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
                justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Nam fermentum, nulla luctus pharetra vulputate, felis
                tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo. Proin sodales pulvinar
                tempor. Cum sociis natoque. <br></br><br></br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo. Proin sodales pulvinar
                tempor. Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien
                nunc eget odio. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
                dolor sit amet lacus accumsan et viverra justo commodo. Proin
                sodales pulvinar tempor. Cum sociis natoque.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Aenean euismod bibendum
                laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
                justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Nam fermentum, nulla luctus pharetra vulputate, felis
                tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo. Proin sodales pulvinar
                tempor. Cum sociis natoque. <br></br><br></br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo. Proin sodales pulvinar
                tempor. Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien
                nunc eget odio. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
                dolor sit amet lacus accumsan et viverra justo commodo. Proin
                sodales pulvinar tempor. Cum sociis natoque.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Aenean euismod bibendum
                laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
                justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Nam fermentum, nulla luctus pharetra vulputate, felis
                tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo. Proin sodales pulvinar
                tempor. Cum sociis natoque. <br></br><br></br>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Terms;
